import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Modal from '@material-ui/core/Modal'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import UpgradeIcon from '@mui/icons-material/Upgrade'
import { useFormik } from 'formik'
import gql from 'graphql-tag'
import 'react-toastify/dist/ReactToastify.css'
import { Rating } from '@material-ui/lab'
import { GridAddIcon } from '@material-ui/data-grid'
import { AgGridReact } from 'ag-grid-react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import ReplayTwoTone from '@material-ui/icons/ReplayTwoTone'
import moment from 'moment'
import GetID from '../components/getID'

const BUILDTICKET = gql`
    mutation buildTicketInput($BuildTicketInput: BuildTicketInput!) {
        buildTicket(ticketInput: $BuildTicketInput) {
            createdById
            ticketSeverity
            ticketType
            ticketSubject
            ticketBody
        }
    }
`
const FINDMYTICKETS = gql`
    query FINDMYTICKETS($id: Int!) {
        employee(id: $id) {
            id
            name
            tickets {
                id
                ticketSeverity
                ticketType
                ticketSubject
                ticketBody
                updatedAt
                purgeQueued
            }
        }
    }
`
const UPDATETICKET = gql`
    mutation UPDATEMYTICKET($id: Int!, $ticketInput: UpdateTicketInput!) {
        updateTicket(id: $id, ticketInput: $ticketInput) {
            ticketSeverity
            ticketStatus
            ticketType
            ticketSubject
            ticketBody
            updatedById
        }
    }
`
const MARK4PURGE = gql`
    mutation Mark4Purge($ticketIdToPurge: Int!) {
        markTicketForPurge(ticketIdToPurge: $ticketIdToPurge)
    }
`
const REVERTPURGE = gql`
    mutation revertPurge($ticketId: Int!) {
        revertPurge(ticketId: $ticketId)
    }
`

const useStyles = makeStyles((theme) => ({
    modalStyle1: {
        position: 'absolute',
        width: '70%',
        height: '70%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'scroll',
        overflowX: 'hidden',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.5rem',
    },
    paper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'scroll',
        overflowX: 'hidden',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '1.5rem',
    },
    button: {
        // always show the button above everything else on the page
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 1,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
        },
    },
}))
const SelfService = () => {
    const classes = useStyles()
    const id = GetID()
    // use formik to handle form input for the BUILDTICKET mutation
    const [buildTicket, {  error: buildError }] = useMutation(BUILDTICKET)


    const { data: tickets, refetch } = useQuery(FINDMYTICKETS, {
        variables: { id: parseFloat(id) },
        fetchPolicy: 'network-only',
        skip: !id,
    })
    
    const [updateTicket] = useMutation(UPDATETICKET)
    const [mark4Purge, { error: purgeMarkingError }] = useMutation(MARK4PURGE)
    const [revertPurge] = useMutation(REVERTPURGE)
    const [severity,setSeverity] = React.useState()
    const [updateSeverity, setUpdateSeverity] = React.useState()
    const [open, setOpen] = React.useState(false)
    const [open2, setOpen2] = React.useState(false)
    const [modalStyle] = React.useState()

    if (buildError) {
        console.log(buildError)
    }
    if (purgeMarkingError) {
        console.log(purgeMarkingError)
    }

    const handleOpen = () => {
        setOpen(true)
    }
    const handleOpen2 = () => {
        setOpen2(true)
    }
    const handleClose = () => {
        setOpen(false)
        setSeverity(0)
        setUpdateSeverity(0)
    }
    const handleClose2 = () => {
        setOpen2(false)
        setSeverity(0)
        setUpdateSeverity(0)
    }

    const handleSubmit = (values) => {
        buildTicket({ variables: { BuildTicketInput: values } })
        handleReset()
        refetch()
        handleClose()
    }
    const handleUpdate = (values, event) => {
        // extract the id from the values object and then delete it
        const Tid = values.id
        delete values.id
        updateTicket({ variables: { id: Tid, ticketInput: values } })
        handleReset()
        refetch()
        handleClose2()
    }
    const purgeTicket = (ticketIdToPurge) => {
        // //console.log(ticketIdToPurge)
        mark4Purge({ variables: { ticketIdToPurge: ticketIdToPurge } })
        refetch()
    }
    const revertPurgeTicket = (ticketId) => {
        revertPurge({ variables: { ticketId: ticketId } })
        refetch()
    }

    const formik = useFormik({
        initialValues: {
            ticketSeverity: 0,
            ticketType: '',
            ticketSubject: '',
            ticketBody: '',
        },
        onSubmit: (values) => {
            // add the user id to the values object as an integer
            values.createdById = parseInt(id)
            handleSubmit(values)
            setSeverity(0)
            setUpdateSeverity(0)
        },
    })
    const formikUpdate = useFormik({
        initialValues: {
            ticketSeverity: 0,
            ticketType: '',
            ticketSubject: '',
            ticketBody: '',
        },
        onSubmit: (values) => {
            values.updatedById = parseInt(id)
            handleUpdate(values)
            setSeverity(0)
            setUpdateSeverity(0)
        },
    })
    // on form submit, set the values of the form to empty strings
    const handleReset = () => {
        formik.resetForm()
    }
    const gridData = tickets
        ? tickets?.employee?.tickets.map((ticket) => {
              return {
                  userID: tickets.employee.id,
                  name: tickets.employee.name,
                  id: ticket.id,
                  ticketSeverity: ticket.ticketSeverity,
                  ticketType: ticket.ticketType,
                  ticketSubject: ticket.ticketSubject,
                  ticketBody: ticket.ticketBody,
                  updatedAt: ticket.updatedAt,
                  pq: ticket.purgeQueued,
              }
          })
        : []

    const columns = [
        {
            field: 'id',
            headerName: 'Ticket ID',
            width: 130,
            sortable: true,
            resizable: true,
            sort: 'asc',
        },
        { field: 'name', headerName: 'Name', width: 200, flex: 0.14 },
        { field: 'ticketSeverity', headerName: 'Severity', width: 95 },
        { field: 'ticketType', headerName: 'Type', width: 125, flex: 0.14 },
        {
            field: 'ticketSubject',
            headerName: 'Subject',
            autoSize: true,
            width: 200,
        },
        { field: 'ticketBody', headerName: 'Body', width: '100%', flex: 1 },
        {
            field: 'updatedAt',
            headerName: 'Updated At',
            width: 120,
            flex: 0.25,
            valueGetter: (params) => {
                return moment(params.data.updatedAt).format('MM/DD/YYYY h:mm a')
            },
        },
        // field that contains an edit button
        {
            field: 'edit',
            headerName: 'Edit',
            width: 100,
            flex: 0.15,
            cellRendererFramework: () => {
                return (
                    <div>
                        <Button variant="contained" color="primary">
                            <UpgradeIcon
                                color="primary"
                                variant="contained"
                                style={{
                                    textDecoration: 'none',
                                    color: '#000',
                                    '&:hover': {
                                        color: '#000',
                                        textDecoration: 'none',
                                    },
                                }}
                            />
                        </Button>
                    </div>
                )
            },
            onCellClicked: (event) => {
                // set the form values to the ticket info
                setUpdateSeverity(event.data.ticketSeverity)
                formikUpdate.setValues({
                    id: event.data.id,
                    ticketSeverity: event.data.ticketSeverity,
                    ticketType: event.data.ticketType,
                    ticketSubject: event.data.ticketSubject,
                    ticketBody: event.data.ticketBody,
                })
                handleOpen2()
            },
        },
        // field that contains a delete button
        {
            field: 'delete',
            headerName: 'Delete',
            width: 100,
            flex: 0.15,
            cellRendererFramework: (event) => {
                return (
                    // if pq is true, change the color of the button to red and change the text to "Deleting"
                    <div>
                        <Button
                            color={event.data.pq ? 'secondary' : 'primary'}
                            variant="contained"
                            onClick={() =>
                                event.data.pq
                                    ? revertPurgeTicket(event.data.id)
                                    : purgeTicket(event.data.id)
                            }
                            style={{
                                textDecoration: 'none',
                                color: '#000',
                                '&:hover': {
                                    color: '#000',
                                    textDecoration: 'none',
                                },
                            }}
                        >
                            {event.data.pq ? 'Revert' : 'Delete'}
                        </Button>
                    </div>
                )
            },
        },
        {
            field: 'pq',
            headerName: 'Purge',
            width: 100,
            flex: 0.15,
            hide: true,
        },
    ]

    return (
        <div>
            <Tooltip title="Refresh Grid Data">
                <IconButton alt="Reload the Grid" aria-label="RefetchQuery" onClick={() => {
                            refetch()
                        }}>
                    <ReplayTwoTone
                    />
                </IconButton>
            </Tooltip>

            <Button
                type="button"
                onClick={handleOpen}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<GridAddIcon />}
            >
                Build Ticket
            </Button>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classes.modalStyle1}>
                    <div className={classes.paper}>
                        <h2 id="simple-modal-title">Build Ticket</h2>
                        <form
                            className={classes.form}
                            onSubmit={formik.handleSubmit}
                        >
                            <Typography
                                // smallest font size
                                variant="h6"
                                gutterBottom
                            >
                                Severity 1-5
                            </Typography>
                            <Rating
                                name="severity"
                                value={updateSeverity}
                                onChange={(event, newValue) => {
                                    setSeverity(newValue)
                                    formik.setFieldValue(
                                        'ticketSeverity',
                                        newValue
                                    )
                                }}
                                size="large"
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="ticketType"
                                label="Type"
                                name="ticketType"
                                autoComplete="ticketType"
                                onChange={formik.handleChange}
                                value={formik.values.ticketType}
                                inputProps={{ maxLength: 12 }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="ticketSubject"
                                label="Subject"
                                name="ticketSubject"
                                autoComplete="ticketSubject"
                                onChange={formik.handleChange}
                                value={formik.values.ticketSubject}
                                inputProps={{ maxLength: 250 }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="ticketBody"
                                label="Body"
                                name="ticketBody"
                                autoComplete="ticketBody"
                                onChange={formik.handleChange}
                                value={formik.values.ticketBody}
                                inputProps={{ maxLength: 1000 }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Submit
                            </Button>
                        </form>
                    </div>
                </div>
            </Modal>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open2}
                onClose={handleClose2}
            >
                <div style={modalStyle} className={classes.modalStyle1}>
                    <div className={classes.paper}>
                        <h2 id="simple-modal-title">
                            Update Ticket #{formikUpdate.values.id}
                        </h2>
                        <form
                            className={classes.form}
                            onSubmit={formikUpdate.handleSubmit}
                        >
                            <Typography
                                // smallest font size
                                variant="h6"
                                gutterBottom
                            >
                                Severity 1-5
                            </Typography>
                            <Rating
                                name="severity"
                                value={updateSeverity}
                                onChange={(event, newValue) => {
                                    setUpdateSeverity(newValue)
                                    formikUpdate.setFieldValue(
                                        'ticketSeverity',
                                        newValue
                                    )
                                }}
                                size="large"
                            />

                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="ticketType"
                                label="Type"
                                name="ticketType"
                                autoComplete="ticketType"
                                onChange={formikUpdate.handleChange}
                                value={formikUpdate.values.ticketType}
                                inputProps={{ maxLength: 12 }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="ticketSubject"
                                label="Subject"
                                name="ticketSubject"
                                autoComplete="ticketSubject"
                                onChange={formikUpdate.handleChange}
                                value={formikUpdate.values.ticketSubject}
                                inputProps={{ maxLength: 250 }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="ticketBody"
                                label="Body"
                                name="ticketBody"
                                autoComplete="ticketBody"
                                onChange={formikUpdate.handleChange}
                                value={formikUpdate.values.ticketBody}
                                inputProps={{ maxLength: 1000 }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit1}
                            >
                                Update #{formikUpdate.values.id}
                            </Button>
                        </form>
                    </div>
                </div>
            </Modal>

            <div
                className="ag-theme-material"
                style={{ height: '84vh', width: '95vw' }}
            >
                <AgGridReact
                    columnDefs={columns}
                    rowData={tickets ? gridData : []}
                    modules={[ClientSideRowModelModule, RowGroupingModule]}
                    paginationAutoPageSize={true}
                    pagination={true}
                    defaultColDef={{
                        // flex: 1,
                        // minWidth: 10,
                        sortable: true,
                        resizable: true,
                        // suppressSizeToFit: true,
                    }}
                    groupMultiAutoColumn={true}
                    enableRangeSelection={true}
                    animateRows={true}
                    enableColResize={true}
                    enableSorting={true}
                    enableFilter={true}
                    enableGroupEdit={true}
                    rowSelection="multiple"
                    rowGroupPanelShow={true}
                    rowGroup={true}
                />
            </div>
        </div>
    )
}
export default SelfService
