import { useAuth0 } from '../react-auth0-spa'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

const GETUSEREmail = gql`
    query findUserID($email: String!) {
        findUserID(email: $email)
    }
`


const GetID = () => {
    const { user } = useAuth0() || {}
    const email = user ? user.email : ''
    const { data } = useQuery(GETUSEREmail, {
        variables: {
            email: email,
        },
    })
    return data?.findUserID
}

export default GetID
