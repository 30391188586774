import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import addentrymutation from './mutations/addEntryMutation'
import {
    Avatar,
    Button,
    Container,
    CssBaseline,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Alert, Autocomplete } from '@material-ui/lab'
import gql from 'graphql-tag'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Fragment } from 'react'
import Modal from '@material-ui/core/Modal'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {Scanner, ScannerKill} from '../components/quagga/scanner'
import {
    onSuccessNotify,
    onErrorNotify,
    onPageLoadSuccess,
    onPageLoadError,
} from '../utils/toast'
import { useAuth0 } from '../react-auth0-spa'
const GET_ASSETS = gql`
    query GetAllAssets {
        assets {
            id
            name
            value
            tag
            inUse
        }
    }
`
const employees = gql`
    query GetAllEmployees {
        employees {
            id
            name
        }
    }
`
const vendors = gql`
query GetAllVendors {
    vendors{
        id
        name
    }
}
`

function getModalStyle() {
    return {
        position: 'absolute',
        left: '50%',
        top: '40%',
        transform: 'translate(-50%, -50%)',
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        maxWidth: 500,
        margin: '0 auto',


        
    },
    modalStyle1: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        width: 325,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    avatar: {
        margin: '0 auto',
        backgroundColor: theme.palette.secondary.main,
        textAlign: 'center',

    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2.5, 0, 2),
    },
}))
const AddAsset = () => {
    const classes = useStyles()
    const [alertParams, setAlertParams] = React.useState({ open: false })
    const [open, setOpen] = React.useState(false)
    const handleAlertClose = () => {
        setAlertParams({ open: false })
    }
    const [modalStyle] = React.useState(getModalStyle)
    // console.log(JSON.stringify({ loggedInUser }))
    const handleOpen = () => {
        setCamera(true)
        setOpen(true)
    }

    const handleClose = () => {
        ScannerKill()
        setCamera(false)
        setOpen(false)
    }

    const [name, setName] = React.useState('')
    const [tag, setTag] = React.useState('')
    const [value, setValue] = React.useState()
    const [inUse, setInUse] = React.useState()
    const [notes, setNotes] = React.useState('')
    const webhookURL =
        'https://hilevel365.webhook.office.com/webhookb2/9c72a6fd-d487-4c07-a9a1-f3eb5a707af7@b6f83ffc-be92-49b1-bbb9-eeb9ed810d3c/IncomingWebhook/802c49cd172b4f8e82564f8c77845a2c/2ef39ac3-227f-480d-b435-827c4af455b2'
    //check if the user has a camera availible
    const [hasCamera, setHasCamera] = React.useState('')
    const [camera, setCamera] = React.useState(false);
    function detectWebcam(callback) {
        let md = navigator.mediaDevices
        if (!md || !md.enumerateDevices) return callback(false)
        md.enumerateDevices().then((devices) => {
            callback(devices.some((device) => 'videoinput' === device.kind))
        })
    }

    detectWebcam(function (hasWebcam) {
        setHasCamera(hasWebcam ? true : false)
    })


    const onDetected = (result) => {
        setTag(result)
        handleClose()
    }

    const [vendorId, setVendor] = React.useState()
    const [usedById, setUsedBy] = React.useState()
    const { user } = useAuth0()
    const userName = user.name ? user.name : user.email
    const {
        data,
        loading: queryLoading,
        error: queryError,
        refetch: queryRefetch,
    } = useQuery(GET_ASSETS, {
        onCompleted: () => {
            onPageLoadSuccess()
        },
        onError: () => {
            onPageLoadError()
        },
    })

    const [
        addAsset,
        { loading: mutationLoading, error: mutationError },
    ] = useMutation(addentrymutation, {
        onCompleted: () => {
            onSuccessNotify()
        },
        onError: () => {
            onErrorNotify()
        },
    })
    const { data: employeesData } = useQuery(employees)
    const employeesArr = [
        ...new Set(
            employeesData? employeesData?.employees.map((employee) => {
                return employee.name
            })
            : []
        ),
    ]

    const { data: vendorsData } = useQuery(vendors)
    const vendorsArr = [
        ...new Set(
            vendorsData? vendorsData?.vendors.map((vendor) => {
                return vendor.name
            })
            : []
        ),

    ]
  const matchVendorToId = (vendorName) => {
        try{
        const vendor = vendorsData.vendors.find(
            (vendor) => vendor.name === vendorName
        )
        return vendor.id
    }
    catch{
        return null
    }
    }
    const matchEmployeeToId = (employeeName) => {
        try{
        const employee = employeesData.employees.find(
            (employee) => employee.name === employeeName
        )
        return employee.id
    }
    catch{
        console.log('error')
        return null
    }
    }


    


    if (queryLoading)
        return (
            <Fragment>
                <ToastContainer
                 />
            </Fragment>
        )
    if (mutationLoading) return <h1>Refreshing</h1>
    if (mutationError)
        return (
            <Fragment>
                <ToastContainer />
            </Fragment>
        )

    const assetsUniq = [
        ...new Set(
            data
                ? data.assets.map((assets) => {
                    return assets.name
                })
                : null
        ),
    ]
    function handleCreateAsset(event) {
        event.preventDefault()
        addAsset({
            variables: {
                name,
                tag,
                value: parseFloat(value),
                inUse: JSON.parse(inUse),
                notes,
                vendorId: parseFloat(vendorId),
                usedById: parseFloat(usedById),
                updatedBy: userName,
            },
        })
            .then(sendWebhook())
            .finally(queryRefetch(),
            setTag(null))
    }
    const sendWebhook = () => {
        // new IncomingWebhook(webhookURL).send(
        //     JSON.stringify({
        //         '@type': 'MessageCard',
        //         '@context': 'https://schema.org/extensions',
        //         summary: 'Asset Created',
        //         themeColor: '0078D7',
        //         title: `A new asset has been created`,
        //         sections: [
        //             {
        //                 activityTitle: 'Created Asset Info',
        //                 activitySubtitle: new Date(),
        //                 // "activityImage": "https://connectorsdemo.azurewebsites.net/images/MSC12_Oscar_002.jpg",

        //                 text: `${userName} added ${name} (Tag: ${tag}) <br/>Value: ${value}`,
        //             },
        //         ],
        //     })
        // )
    }

    const scannerBody = (
        <div id='camera-modal' style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">Tag Scanner</h2>
            {camera && <Scanner onDetected={onDetected} />}
            <Button type="button" onClick={handleClose}>
                Close
            </Button>
        </div>
    )

    return (
        <Container component="main">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AddShoppingCartIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Register Entry{' '}
                </Typography>{' '}
                <br />
                <Fragment>
                    <ToastContainer
                     />
                </Fragment>
                <form className={classes.form}>
                    <Grid container spacing={2}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleOpen}
                            disabled={hasCamera === false}
                        >
                            {hasCamera === false
                                ? 'No Comptatible Camera'
                                : 'Scan Tag'}
                        </Button>
                        <Modal open={open}>{scannerBody}</Modal>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={assetsUniq ? assetsUniq : []}
                                getOptionLabel={(assetsUniq) => assetsUniq}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Asset Name"
                                        fullWidth
                                        variant="outlined"
                                        required
                                    />
                                )}
                                freeSolo={true}
                                disabled={queryError}
                                fullWidth
                                onInputChange={(event, params) =>
                                    setName(params)
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                onChange={(event) => setTag(event.target.value)}
                                label="Asset Tag"
                                value={tag}
                                fullWidth
                                variant="outlined"
                                required
                                disabled={queryError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                onChange={(event) =>
                                    setValue(event.target.value)
                                }
                                type="number"
                                label="Value"
                                fullWidth
                                variant="outlined"
                                required
                                disabled={queryError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.form}>
                                <InputLabel required>Asset in Use?</InputLabel>

                                <Select
                                    variant="outlined"
                                    disabled={queryError}
                                    defaultValue=""
                                    required
                                    fullWidth
                                    onChange={(event) =>
                                        setInUse(event.target.value)
                                    }
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <InputLabel className={classes.heading}>
                                        Additional Information
                                    </InputLabel>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={classes.form}>
                                       
                                        <Grid item xs={12}>
                                        <Autocomplete
                                options={ vendorsArr ? vendorsArr : []}
                                getOptionLabel={(vendorsArr) => vendorsArr}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Vendor Name"
                                        fullWidth
                                        variant="outlined"
                                    />
                                )}
                                freeSolo={false}
                                disabled={queryError}
                                fullWidth
                                onChange={(event, params) => {
                                    setVendor(matchVendorToId(params))
                                }}
                            />
                                        </Grid> 
                                        <Grid item xs={12}>
                                        <Autocomplete
                                options={ employeesArr ? employeesArr : []}
                                getOptionLabel={(employeesArr) => employeesArr}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Employee Name"
                                        fullWidth
                                        variant="outlined"
                                    />
                                )}
                                freeSolo={false}
                                disabled={queryError}
                                fullWidth
                                // log the entered value
                                onChange={(event, params) => {
                                    setUsedBy(matchEmployeeToId(params))
                                }}
                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                onChange={(event) =>
                                                    setNotes(event.target.value)
                                                }
                                                type="text"
                                                label="Notes"
                                                fullWidth
                                                variant="outlined"
                                                disabled={queryError}
                                            />
                                        </Grid>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                    <Button
                        disabled={(queryLoading, queryError)}
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleCreateAsset}
                    >
                        Add to Database
                    </Button>
                    {mutationError && <p>{mutationError.message}</p>}
                </form>
                <Snackbar
                    open={alertParams.open}
                    autoHideDuration={6000}
                    onClose={handleAlertClose}
                >
                    <Alert
                        onClose={handleAlertClose}
                        severity={alertParams.severity}
                        elevation={6}
                        variant="filled"
                    >
                        {alertParams.message}
                    </Alert>
                </Snackbar>
                <br />
            </div>
        </Container>
    )
}
export default AddAsset
//Set this to modal tomorrow, such that it pops out on click and exits once a result has been found
//Could also include a snackbar/alert on result being found. Or an audible beep
