import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
    Avatar,
    Button,
    Container,
    CssBaseline,
    Grid,
    InputLabel,
    Snackbar,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Alert, Autocomplete } from '@material-ui/lab'
import gql from 'graphql-tag'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Fragment } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { onPageLoadSuccess, onPageLoadError } from '../utils/toast'

const GET_EMPLOYEES = gql`
    query GetAllEmployees {
        employees {
            name
            title
            initials
            email
        }
    }
`
const ADD_EMPLOYEES = gql`
    mutation AddEmployee(
        $name: String!
        $title: String!
        $initials: String!
        $email: EmailAddress!
    ) {
        addEmployee(
            employeeInput: {
                name: $name
                title: $title
                initials: $initials
                email: $email
                active: true
            }
        ) {
            name
            title
            initials
            email
            active
        }
    }
`

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))
const EmployeeMgr = () => {
    const classes = useStyles()
    const [alertParams, setAlertParams] = React.useState({ open: false })
    const handleAlertClose = () => {
        setAlertParams({ open: false })
    }

    const [name, setname] = React.useState('')
    const [title, setTitle] = React.useState('')
    const [initials, setInitials] = React.useState('')
    const [email, setEmail] = React.useState('')
    //console.log(name, title, initials, email)
    const {
        data,
        loading: queryLoading,
        error: queryError,
        refetch: queryRefetch,
    } = useQuery(GET_EMPLOYEES, {
        pollInterval: 30000,
        onCompleted: () => {
            onPageLoadSuccess()
        },
        onError: () => {
            onPageLoadError()
        },
    })
    const [addEmployee] = useMutation(ADD_EMPLOYEES)

    const handleaddNewEmployee = (event) => {
        event.preventDefault()
        addEmployee({
            variables: {
                name,
                title,
                initials,
                email,
            },
        }).finally(queryRefetch(handleClearForm))
    }
    // set the form to empty after adding a new employee
    const handleClearForm = () => {
        setname('')
        setTitle('')
        setInitials('')
        setEmail('')
    }

    const assetsUniq = [
        ...new Set(
            data
                ? data.employees.map((employees) => {
                      return employees.name
                  })
                : null
        ),
    ]

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <PersonAddIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Register New Employee{' '}
                </Typography>{' '}
                <br />
                <Fragment>
                    <ToastContainer />
                </Fragment>
                <form className={classes.form}>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={assetsUniq ? assetsUniq : []}
                            getOptionLabel={(assetsUniq) => assetsUniq}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Employee Name"
                                    fullWidth
                                    variant="outlined"
                                    required
                                />
                            )}
                            freeSolo={true}
                            disabled={queryError}
                            fullWidth
                            onInputChange={(event, params) => {
                                setname(params)
                                setInitials(
                                    params
                                        .match(/(\b\S)?/g)
                                        .join('')
                                        .toUpperCase()
                                )
                                setEmail(
                                    `${params
                                        .replace(/\s/g, '.')
                                        .toLowerCase()}@hi-level.co.uk`
                                )
                            }}
                        />
                    </Grid>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <InputLabel className={classes.heading}>
                                Employee Information
                            </InputLabel>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className={classes.form}>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={(event) =>
                                            setTitle(event.target.value)
                                        }
                                        type="text"
                                        label="Job Title"
                                        fullWidth
                                        variant="outlined"
                                        disabled={queryError}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={(event) =>
                                            setInitials(event.target.value)
                                        }
                                        type="text"
                                        label="Employee Initials"
                                        value={name
                                            .match(/(\b\S)?/g)
                                            .join('')
                                            .toUpperCase()}
                                        fullWidth
                                        variant="outlined"
                                        disabled={queryError}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        onChange={(event) =>
                                            setEmail(event.target.value)
                                        }
                                        // set the value the employee name, replace the spaces with an .
                                        value={
                                            name.length > 1
                                                ? `${name
                                                      .replace(/\s/g, '.')
                                                      .toLowerCase()}@hi-level.co.uk`
                                                : ''
                                        }
                                        type="text"
                                        label="Employee Email"
                                        fullWidth
                                        variant="outlined"
                                        disabled={queryError}
                                    />
                                </Grid>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </form>
                <Button
                    disabled={(queryLoading, queryError)}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleaddNewEmployee}
                >
                    Add to Database
                </Button>
                <Snackbar
                    open={alertParams.open}
                    autoHideDuration={6000}
                    onClose={handleAlertClose}
                >
                    <Alert
                        onClose={handleAlertClose}
                        severity={alertParams.severity}
                        elevation={6}
                        variant="filled"
                    >
                        {alertParams.message}
                    </Alert>
                </Snackbar>
                <br />
            </div>
        </Container>
    )
}
export default EmployeeMgr
