import gql from 'graphql-tag'

export default gql`
   
    mutation UpdateAsset(
        $id: Int!
        $name: String!
        $tag: String!
        $value: Float!
        $inUse: Boolean!
        $notes: String
        $usedById: Int
        $vendorId: Int
        $updatedBy: String!
    ) {
        updateAsset(
            id: $id
            assetInput: {
                name: $name
                tag: $tag
                value: $value
                inUse: $inUse
                notes: $notes
                usedById: $usedById
                vendorId: $vendorId
                updatedBy: $updatedBy
            }
        ) {
            name
            tag
            value
            inUse
            notes
            usedBy{
                name
            }
            vendor{
                name
            }
            updatedBy
        }
    }


`
