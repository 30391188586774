import React, { Fragment } from 'react'
import gql from 'graphql-tag'
import { DataGrid, GridToolbar } from '@material-ui/data-grid'
import { useQuery, useMutation } from '@apollo/client'
// import DeleteAsset from './mutations/deleteAsset';
import {
    makeStyles,
    Backdrop,
    CircularProgress,
    Snackbar,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ReplayTwoToneIcon from '@material-ui/icons/ReplayTwoTone'
import Tooltip from '@material-ui/core/Tooltip'
import { formatISO9075 } from 'date-fns'
import parseISO from 'date-fns/parseISO'
import GetRoles from '../components/getRoles'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}))

const PULLEMPDATA = gql`
    {
        employees {
            id
            name
            title
            initials
            email
            createdAt
            updatedAt
            vehicle {
                id
            }
            assets {
                id
            }
            active
        }
    }
`
const UPDATEACTIVESTATE = gql`
    mutation updateActiveState($id: Int!, $active: Boolean!) {
        updateEmployee(id: $id, employeeInput: { active: $active }) {
            id
            active
        }
    }
`

const dateFormatter = (params) => {
    return params.value
        ? formatISO9075(parseISO(params.value), { representation: 'date' })
        : null
}
const createdAtFormatter = (params) => {
    return params.row.createdAt
        ? formatISO9075(parseISO(params.row.createdAt), {
              representation: 'date',
          })
        : null
}

const EmployeePortal = () => {
    const { loading, error, data, refetch: queryRefetch } = useQuery(
        PULLEMPDATA,
        {
            // pollInterval: 5000,
        }
    )
    const hasRoles = GetRoles()
    const [updateActiveState] = useMutation(UPDATEACTIVESTATE)

    const classes = useStyles()
    if (loading) {
        return (
            <Fragment>
                <Snackbar
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    open={loading}
                    autoHideDuration={6000}
                    message={'Hang on... Just Loading'}
                />
                <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                    <br />
                </Backdrop>
            </Fragment>
        )
    }
    if (error)
        return (
            <Fragment>
                <Snackbar
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    open={error}
                    autoHideDuration={6000}
                    message={'Hmmm... Something went Wrong'}
                />
                <h1>Something went wrong...</h1>
            </Fragment>
        )

    // deconstruct the data into variables
    const { employees } = data

    const updateActive = (id, active) => {
        updateActiveState({
            variables: {
                id,
                active,
            },
        })
    }

    const columns = [
        { headerName: 'ID', field: 'id', hidden: true },
        { headerName: 'Name', field: 'name', type: 'string', width: 130 },
        { headerName: 'Title', field: 'title', type: 'string', width: 130 },
        {
            headerName: 'Initials',
            field: 'initials',
            type: 'string',
            width: 80,
            editable: true,
        },
        { headerName: 'Email', field: 'email', type: 'string', width: 200 },
        {
            headerName: 'Created At',
            field: 'createdAt',
            type: 'string',
            renderCell: createdAtFormatter,
            width: 130,
        },
        {
            headerName: 'Updated At',
            field: 'updatedAt',
            type: 'string',
            renderCell: dateFormatter,
            width: 130,
        },
        {
            headerName: 'Is Current?',
            field: 'active',
            type: 'bool',
            width: 130,
            renderCell: (params) => {
                return (
                    <Fragment>
                        <input
                            disabled={!hasRoles.includes('admin', 'superadmin')}
                            type="checkbox"
                            checked={params.value}
                            readOnly={!hasRoles.includes('admin', 'superadmin')}
                            onClick={() => {
                                updateActive(params.row.id, !params.value)
                                queryRefetch()
                            }}
                        />
                    </Fragment>
                )
            },
        },
    ]
    // map the response from data to the columns
    return (
        <Fragment>
            <div style={{ height: '84vh', width: '95vw' }}>
                <Tooltip title="Refresh Grid Data">
                    <IconButton alt="Reload the Grid" aria-label="RefetchQuery">
                        <ReplayTwoToneIcon onClick={() => queryRefetch()} />
                    </IconButton>
                </Tooltip>

                <DataGrid
                    paginationMode="client"
                    // autoPageSize="true"
                    rows={employees}
                    columns={columns}
                    rowHeight={50}
                    sortModel={[
                        {
                            field: 'id',
                            sort: 'asc',
                        },
                    ]}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                />
                <Tooltip title="Refresh Grid Data">
                    <IconButton alt="Reload the Grid" aria-label="RefetchQuery">
                        <ReplayTwoToneIcon onClick={() => queryRefetch()} />
                    </IconButton>
                </Tooltip>
            </div>
        </Fragment>
    )
}

export default EmployeePortal
