import React, { Fragment } from 'react'
import gql from 'graphql-tag'
import { DataGrid, GridToolbar } from '@material-ui/data-grid'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import {
    makeStyles,
    Backdrop,
    CircularProgress,
    Snackbar,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ReplayTwoToneIcon from '@material-ui/icons/ReplayTwoTone'
import Tooltip from '@material-ui/core/Tooltip'
import { formatISO9075 } from 'date-fns'
import parseISO from 'date-fns/parseISO'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import GetRoles from '../components/getRoles'

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'auto',
        width: 'auto',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    drawerHeader: {
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        // flexGrow: 1,
        padding: theme.spacing(3),
    },
}))

const GETASSETS = gql`
    {
        assets {
            id
            name
            tag
            value
            inUse
            notes
            purchaseDate
            warrantyUntil
            usedBy {
                name
                title
                email
            }
            vendor {
                name
            }
            createdAt
            updatedAt
            updatedBy
        }
    }
`
const dateFormatter = (params) => {
    return params.value
        ? formatISO9075(parseISO(params.value), { representation: 'date' })
        : null
}
const createdAtFormatter = (params) => {
    return params.row.createdAt
        ? formatISO9075(parseISO(params.row.createdAt), {
              representation: 'date',
          })
        : null
}
const eolFormatter = (params) => {
    return params.value
        ? differenceInWeeks(new Date(params.value), new Date())
        : null
}
const emailGetter = (params) => {
    return params.row ? params.row.usedByEmail : null
}
const currencyFormatter = (params) => {
    return params.value
        ? params.value.toLocaleString('en-GB', {
              style: 'currency',
              currency: 'GBP',
              minimumFractionDigits: 0,
          })
        : null
}

const inUseFormatter = (params) => {
    return params.value === true ? 'Yes' : 'No'
}

const dataDumpChecker = (params) => {
    if (params.row.updatedBy === 'data_dump') {
        return 'N/A'
    } else {
        return params.row.updatedBy ? params.row.updatedBy : ''
    }
}
const ViewAsset = () => {
    const { loading, error, data, refetch: queryRefetch } = useQuery(
        GETASSETS,
        {
            // pollInterval: 5000,
        }
    )
    const hasRoles = GetRoles()

    const classes = useStyles()
    if (loading) {
        return (
            <Fragment>
                <Snackbar
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    open={loading}
                    autoHideDuration={6000}
                    message={'Hang on... Just Loading'}
                />
                <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                    <br />
                </Backdrop>
            </Fragment>
        )
    }
    if (error)
        return (
            <Fragment>
                <Snackbar
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    open={error}
                    autoHideDuration={6000}
                    message={'Hmmm... Something went Wrong'}
                />
                <h1>Something went wrong...</h1>
            </Fragment>
        )
    const dataUnwrapped = data.assets.map((asset) => ({
        id: asset.id,
        name: asset.name,
        tag: asset.tag,
        value: asset.value,
        inUse: asset.inUse,
        notes: asset.notes,
        purchaseDate: asset.purchaseDate,
        warrentyUntil: asset.warrentyUntil,
        usedBy: asset.usedBy ? asset.usedBy.name : undefined,
        usedByTitle: asset.usedBy ? asset.usedBy.title : undefined,
        usedByEmail: asset.usedBy ? asset.usedBy.email : undefined,
        vendor: asset.vendor ? asset.vendor.name : undefined,
        createdAt: asset.createdAt,
        updatedAt: asset.updatedAt,
        updatedBy: asset.updatedBy,
    }))

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 70,
            // sort from highest to lowest
            sortComparator: (v1, v2, param1, param2) =>
                param2.row.id - param1.row.id,
            renderCell: (params) =>
                // check if hasRoles exists in editReqRoles array
                hasRoles.some((role) =>
                    ['admin', 'superadmin'].includes(role)
                ) ? (
                    <strong>
                        <Link
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                            to={() => '/views/' + params.value + '/editAsset'}
                        >
                            {params.value}
                        </Link>
                    </strong>
                ) : (
                    <strong>{params.value}</strong>
                ),
        },
        { field: 'name', headerName: 'Name', width: 220 },
        { field: 'tag', headerName: 'Asset Tag', width: 120 },
        {
            field: 'value',
            headerName: 'Value',
            width: 100,
            renderCell: currencyFormatter,
        },
        {
            field: 'inUse',
            headerName: 'Assigned?',
            width: 120,
            renderCell: inUseFormatter,
        },
        {
            field: 'usedBy',
            headerName: 'Used By?',
            width: 175,
            renderCell: (params) => (
                // //console.log(params.row.usedBy),
                // <Tooltip title={params ? params?.row?.usedByTitle : 'N/A'}>
                //     <div>
                //         <a
                //             href={'mailto:' + emailGetter(params)}
                //             target="_blank"
                //             rel="noreferrer"
                //         >
                //             {params ? params?.row?.usedBy : 'N/A'}
                //         </a>
                //     </div>
                // </Tooltip>
                <div>
                    {params.row.usedBy ? (
                        <Tooltip
                            title={
                                params ? params?.row?.usedByTitle : null
                            }
                        >
                            <div>
                                <a
                                    href={
                                        'mailto:' + emailGetter(params)
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {params
                                        ? params?.row?.usedBy
                                        : null}
                                </a>
                            </div>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                </div>
            ),
        },

        {
            field: 'vendor',
            headerName: 'Vendor',
            width: 125,
        },
        {
            field: 'purchaseDate',
            headerName: 'Date of Purchase',
            width: 175,
            renderCell: dateFormatter,
        },

        {
            field: 'warrentyUntil',
            headerName: 'EOL',
            width: 175,
            renderCell: (params) => (
                <Tooltip
                    title={eolFormatter(params) + ' Weeks until end of life'}
                >
                    <div>{dateFormatter(params)}</div>
                </Tooltip>
            ),
        },

        {
            field: 'notes',
            width: 150,
            renderCell: (params) => {
                const notes = params.value
                return notes
            },
        },
        {
            field: 'updatedAt',
            headerName: 'Latest Change @',
            width: 175,
            renderCell: (params) => (
                <Tooltip title={' Created at: ' + createdAtFormatter(params)}>
                    <div>{dateFormatter(params)}</div>
                </Tooltip>
            ),
        },
        {
            field: 'updatedBy',
            headerName: 'Last Changed By',
            width: 175,
            renderCell: dataDumpChecker,
        },
    ]
    return (
        <Fragment>
            {/* push the content to the left, and ensure it does not overlap, making the user scroll */}
            <div style={{ width: 'auto', height: '75vh' }}>

                <Tooltip title="Refresh Grid Data">
                    <IconButton alt="Reload the Grid" onClick={() => queryRefetch()} aria-label="RefetchQuery">
                        <ReplayTwoToneIcon  />
                    </IconButton>
                </Tooltip>

                <DataGrid
                    paginationMode="server"
                    //autoPageSize="true"
                    rows={dataUnwrapped ? dataUnwrapped : []}
                    columns={columns}
                    sortModel={[
                        {
                            field: 'id',
                            sort: 'asc',
                        },
                    ]}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                />
            </div>
        </Fragment>
    )
}

export default ViewAsset
