import React from 'react'
import { useQuery } from '@apollo/client'
import getCarData from '../views/mutations/getCarData'
// import what need to do a recharts pie chart
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

let COLORS = [
    '#0088FE',
    '#96C49F',
    '#FFBB28',
    '#FF8042',
    '#FF0000',
    '#FF00FF',
    '#BF26DB',
    '#010FA5',
    '#F56310',
    '#05F510',
    '#086310',
    '#430F9D',
    '#45D199',
    '#927017',
    '#D956B6',
    '#12D3F7',
]



const Graph = () => {
    const { data } = useQuery(getCarData)
    // the data we are after is in vehicles.lastMonthData, so map over that into an array
    // of objects with the keys x and y, where x is the name of the car and y is the sum of the last month's data
    const dataToGraph = data?.vehicles?.map((vehicle) => {
        return {
            id: vehicle.id,
            x: vehicle.name,
            y: vehicle.lastMonthData?.sum,
        }
    })
    let renderLabel = function (entry) {
        // render the name with the sum below it
        return `${entry.name}: ${entry.value}`
    }
// get all the sums that are greater than 0 and add them together
    let total = dataToGraph?.filter((vehicle) => vehicle.y > 0).map((vehicle) => vehicle.y)?.reduce((a, b) => a + b, 0).toFixed(2).toString()
    // get the current month 
    let month = new Date().toLocaleString('default', { month: 'long' })


    // work out the size of the screen and set the zoom accordingly
    const [width, setWidth] = React.useState(100)
    const [height, setHeight] = React.useState(100)
    const [zoom, setZoom] = React.useState(1)
    React.useEffect(() => {
    if (window.innerWidth < 400) {
        setZoom(0.6)
        setWidth('95%')
        setHeight('50vh')
    } else if (window.innerWidth < 500) {
        setZoom(0.4)
        setWidth('50%')
        setHeight('50vh')
    } else if (window.innerWidth < 700) {
        setZoom(0.6)
        setWidth('70%')
        setHeight('60vh')
    } else {
        setZoom(1)
        setWidth('75%')
        setHeight('60vh')
    }
    }, [])

    return (
        <div style={{
             width: width,
              height: height,
               backgroundColor:"black",
                opacity:'60%',
                margin: 'auto',
                textAlign: 'center',
                position: 'fixed',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                zoom: zoom,






                // overflow: 'hidden'
                   }}>
            <h5 style={{ color: 'white' }}>{month}: {total}</h5>
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        data={dataToGraph?.filter((vehicle) => vehicle.y > 0)}
                        dataKey="y"
                        nameKey="x"
                        // add a tooltip to the pie chart
                        label={renderLabel}
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        style={{ fontSize: '1rem', fontWeight: 'bold', resize: 'both' }}
                    >
                        {dataToGraph?.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    )
}

export default Graph
