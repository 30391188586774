import React, { Fragment } from 'react'
import { useAuth0 } from '../react-auth0-spa'
import GetRoles from './getRoles'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/client'
import './compCSS/profile.css'
import { TextField, Button } from '@material-ui/core'
import {toast, ToastContainer} from 'react-toastify'
import GetAPIAbout from './GetAPIAbout'
import PushNotifications from './admin/pushNotifications'

const getAuth0UserInfo = gql`
query ($id: String!) {
    auth0User (
        id: $id
    ) {
        name
        email
        username
        nickname
        given_name
        family_name
        picture
    }
}
    `
const updateAuth0User = gql`
mutation ($userInput: Auth0UpdateUser!) {
    auth0UpdateUser (
        userInput: $userInput
    ) {
        name
    }
}
`

const Profile = () => {
    const { user } = useAuth0()
    const [userData, setUserData] = React.useState(null)
    const [inputs, setInputs] = React.useState({});
    const [modifiedFields, setModifiedFields] = React.useState({});
    const [isAboutOpen, setIsAboutOpen] = React.useState(false)

    const closeAbout = () => {
        setIsAboutOpen(false);
    }

    const { data: dataUser, refetch: userRefetch } = useQuery(getAuth0UserInfo, {
        variables: { id: user?.sub },
        displayName: "GetAuth0UserInfo",
        skip: user === null,
    })
    const [updateUser] = useMutation(updateAuth0User, {
        displayName: "UpdateAuth0User",
        skip: user === null,
    })

    React.useEffect(() => {
        if (dataUser) {
            setUserData(dataUser.auth0User)
        }
    }, [dataUser])
    React.useEffect(() => {
        setInputs({ ...userData });
    }, [userData]);

    const updateHandler = () => {
        // check if modifiedFields is empty and if so, alert the user
        if (Object.keys(modifiedFields).length === 0) {
         alert("No changes made")

        } else {
            // Making server call
            toast.promise(
            updateUser({
                variables: {
                    userInput: {
                        user_id: user.sub,
                        ...modifiedFields
                    }
                }
        })
        , {
            loading: 'Updating...',
            success: 'Updated!',
            error: 'Error'
        })
        .then(() => {
            userRefetch()
        }).finally(() => {
            setModifiedFields({})
        })
        }
      }
      

    const renderUserData = () => {
        // render a series of input fields to allow the user to update their profile. Filtet out __typename
        if (userData) {
            return (
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(inputs)
                                .filter((key) => key !== "__typename")
                                .map((key, index) => (
                                //     // if app_metadata, json parse it so it can be edited
                                //     key === "app_metadata" && GetRoles().includes("superadmin") ?
                                //     <tr key={index}>
                                //     <td>{key}</td>
                                //     <td>
                                //         <TextField
                                //         fullWidth
                                //             type="text"
                                //             value={JSON.stringify(inputs[key]) || ""}
                                //             onChange={(e) => {
                                //                 const newInputs = { ...inputs };
                                //                 newInputs[key] = JSON.parse(e.target.value);
                                //                 setInputs(newInputs);
                                //                 setModifiedFields({ ...modifiedFields, [key]: JSON.parse(e.target.value) })
                                //             }}
                                //         />
                                //     </td>
                                // </tr>
                                //     :
                        
                                    <tr key={index}>
                                        <td>{key}</td>
                                        <td>
                                            <TextField
                                            fullWidth
                                                type="text"
                                                value={inputs[key] || ""}
                                                onChange={(e) => {
                                                    const newInputs = { ...inputs };
                                                    newInputs[key] = e.target.value;
                                                    setInputs(newInputs);
                                                    setModifiedFields({ ...modifiedFields, [key]: e.target.value })
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <Button onClick={updateHandler}>Update</Button>
                </div >
            );
        }
    }

    const renderAPIAbout = () => {
        if (isAboutOpen) {
            return (
                // render this above all other data
                <div className='about-modal'>
                <GetAPIAbout isOpen={isAboutOpen} close={closeAbout}/>
                </div>

            )
        }
    }




    return (
        <Fragment>
            {renderAPIAbout()}
            <img src={user.picture} alt="Profile" />
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                closeButton={true}
             />
            <h2>{user.name}</h2>
            <p>{GetRoles()?.toString()}</p>
            {renderUserData()}
            <div>
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                    {/* <code>{JSON.stringify(user, null, 2)}</code> */}
                </pre>
            </div>
            <div className='floating-about-button'>
              <Button variant='contained' color='secondary' onClick={() => setIsAboutOpen(!isAboutOpen)}>About</Button>
            </div>
        </Fragment>
    )
}

export default Profile
