import React, { useEffect } from 'react'
import { Router, Switch, Redirect } from 'react-router-dom'
import {
    ApolloProvider,
    ApolloClient,
    InMemoryCache,
    createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { default as data } from '../package.json'
import { LicenseManager } from '@ag-grid-enterprise/core'
import NavBar from './components/NavBar'
import NavDrawer from './components/NavDrawer'
import { useAuth0 } from './react-auth0-spa'
import Profile from './components/Profile'
import Landing from './landing'
import ViewAsset from './views/table'
import ReturnMilage from './views/teslaTable'
import AddAsset from './views/addAsset'
import history from './utils/history'
import PrivateRoute from './components/PrivateRoute'
import EditAsset from './views/editAsset'
import Copyright from './utils/copyright'
import EmployeePortal from './views/employeePortal'
import EmployeeMgr from './views/employeemgr'
import Debug from './tools/debug'
import SelfService from './views/selfService'
import AddAssetFormikRewrite from './views/addAssetFormikRewrite'
import Employees from './views/employees'
import Graph from './components/graph'
import NotificationsModal from './components/notifications'


LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID)

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    // slightly enlarge the button when hovering
    '@global': {
        '@media (hover: hover)': {
            '.MuiButton-root:hover': {
                boxShadow:
                    '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
            },
        },
    },
}))

const App = () => {
    const { loading, getTokenSilently, user } = useAuth0()
    const classes = useStyles()
    const httpLink = createHttpLink({
        uri: process.env.REACT_APP_GRAPHQL,
    })
    const authLink = setContext(async (_, { headers }) => {
        const token = await getTokenSilently()

        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : '',


            },
        }
    })

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        connectToDevTools: true,
        // no cache for now
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        notifications: {
                            merge(existing, incoming) {
                                return incoming
                            }
                        },
                    },
                },
            },
        }),
        // name: 'Hi-Level AMS',
        // version: data?.version,
    })

    // wait until the user is loaded and then set the local storage email to the user email
    useEffect(() => {
        if (user) {
            sessionStorage.setItem('email', user.email)
        }
    }, [user])




    if (loading) {
        return (
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit"  />
            </Backdrop>
        )
    }


    return (
        <div className={classes.root}>
            <CssBaseline />
            <Router history={history}>
                <ApolloProvider client={client}>

                    <header>
                        <NavBar />
                        {user &&
                            <NavDrawer />
                        }
                    </header>

                    <div className={classes.content}>
                        <div className={classes.drawerHeader} />
                        <Switch>
                            <PrivateRoute path="/profile" component={Profile}/>
                            <PrivateRoute
                                path="/views/table/"                        
                                component={ViewAsset}
                                roles={['superadmin', 'director']}
                            />
                            <PrivateRoute
                                path="/views/employees/"
                                roles={['superadmin']}
                                component={Employees}
                            />

                            <PrivateRoute
                                roles={['superadmin', 'director']}
                                path="/views/teslaTable/"
                                component={ReturnMilage}
                            />
                            <PrivateRoute
                                roles={['superadmin']}
                                path="/views/addAsset"
                                component={AddAsset}
                            />
                            <PrivateRoute
                                roles={['superadmin']}
                                path="/views/sa"
                                component={AddAssetFormikRewrite}
                            />
                            <PrivateRoute path="/landing" component={Landing} />
                            <PrivateRoute
                                roles={['superadmin']}
                                path="/views/editAsset"
                                component={EditAsset}
                            />
                            <PrivateRoute
                                roles={['superadmin']}
                                path="/views/employeePortal"
                                component={EmployeePortal}
                            />
                            <PrivateRoute
                                path="/views/employeemgr"
                                roles={['superadmin']}
                                component={EmployeeMgr}
                            />
                            <PrivateRoute
                                roles={['deprecated']}
                                path="/views/selfService"
                                component={SelfService}
                            />

                            <PrivateRoute //Debug
                                path="/tools/debug"
                                component={Debug}
                                roles={['superadmin']}
                            />

                            <PrivateRoute
                                roles={['superadmin']}
                                path="/views/:Aid/editAsset"
                                component={EditAsset}
                            ></PrivateRoute>
                            <PrivateRoute
                                roles={['superadmin']}
                                path="/components/graph"
                                component={Graph}
                            ></PrivateRoute>
                            <PrivateRoute
                                path="/components/notifications"
                                component={NotificationsModal}
                            ></PrivateRoute>

                            <Redirect to="/landing" />
                        </Switch>
                        <br /> <br /> <br />
                        <br />
                        <br />
                        {Copyright()}{}
                    </div>
                </ApolloProvider>
            </Router>
        </div>
    )
}

export default App
