// import { format } from 'date-fns';
// import parseISO from 'date-fns/parseISO';
import React, { Fragment } from 'react'
import axios from 'axios'
import { Button, TextField } from '@material-ui/core'
import { useAuth0 } from '../react-auth0-spa'
import GetRoles from '../components/getRoles'
import { ToastContainer, toast } from 'react-toastify';


const Debug = () => {
    const { getTokenSilently } = useAuth0()
    const [message, setMessage] = React.useState('')
    const [param, setParam] = React.useState()
    const isAdmin = GetRoles()?.includes('admin') ? false : true

    const getData = async () => {
        const token = await getTokenSilently()
        const config = {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            },
        }
        const res = await axios.get(
            `http://localhost:3000/dev/checker/${param}`,
            config
        )
        setMessage(res.data.message)
    }

    // test the promise based toast mimicing the loading on success of graphql mutations through a function that will be invoked by a button
    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 3000));
    toast.promise(
        resolveAfter3Sec,
        {
          pending: 'Promise is pending',
          success: 'Promise resolved 👌',
          error: 'Promise rejected 🤯'
        }
    )

    return (
        <div>
            <Fragment>
            <ToastContainer
position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
                <TextField
                    id="outlined-basic"
                    label="Param"
                    variant="outlined"
                    onChange={(e) => setParam(e.target.value)}
                />
                <Button variant="outlined" disabled={isAdmin} onClick={getData}>
                    Get Data
                </Button>

                <h1>{message}</h1>

                <Button variant="outlined" onClick={resolveAfter3Sec}>
                    Notify
                </Button>

            </Fragment>
        </div>
    )
}

export default Debug
