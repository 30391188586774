import React from 'react'
import clsx from 'clsx'
import {
    Button,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    makeStyles,
    Badge
} from '@material-ui/core'
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { Menu as MenuIcon } from '@material-ui/icons'
import { useRecoilState } from 'recoil'
import { useAuth0 } from '../react-auth0-spa'
import { drawerOpenState } from '../atoms'
import NavProfile from './NavProfile'
import { Link } from 'react-router-dom'
import GetRoles from './getRoles'
import GetNotifs from './getNotifs';




const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        fontSize: '1.25rem',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
}))

const NavBar = () => {
    const classes = useStyles()
    const hasRoles = GetRoles()
    const ID = sessionStorage.getItem('email')
    const notifs = GetNotifs(ID)
    const { isAuthenticated, loginWithRedirect } = useAuth0()
    const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState)
    const handleDrawerOpen = () => {
        setDrawerOpen(true)
    }





    return (
        <main>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: drawerOpen,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(
                            classes.menuButton,
                            drawerOpen && classes.hide
                        )}
                    >
                        <MenuIcon />
                    </IconButton>
                    {!isAuthenticated && (
                        <Typography
                            variant="h6"
                            align="center"
                            className={classes.title}
                        >
                            Hi-Level Asset system
                        </Typography>
                    )}
                    {isAuthenticated && (
                        <Typography
                            variant="h6"
                            align="center"
                            className={classes.title}
                            to="/landing"
                            component={Link}
                            style={{ color: '#FFF' }}
                            title="Routes back to landing page"
                        >
                            Hi-Level Asset system
                        </Typography>
                    )}
                    {process.env.NODE_ENV === 'development' &&
                        hasRoles.some((role) =>
                            ['devadmin'].includes(role)
                        ) && (
                            <Button
                                color="inherit"
                                component={Link}
                                to="/components/graph"
                            >
                                Graph
                            </Button>
                        )}
                    {!isAuthenticated && (
                        <Button
                            color="inherit"
                            onClick={() => loginWithRedirect({})}
                        >
                            Log in
                        </Button>
                    )}
                    {isAuthenticated && (
                        <IconButton
                            color="inherit"
                            title="Notifications"
                            aria-label="show 0 new notifications"
                            component={Link}
                            to="/components/notifications"

                        >
                            <Badge badgeContent={notifs ? notifs?.length : 0} color="secondary">
                                <CircleNotificationsIcon
                                color="inherit"
                                 />
                            </Badge>
                        </IconButton>

                        
                           
                    )}
                    {isAuthenticated && <NavProfile />}
                </Toolbar>
            </AppBar>
        </main>
    )
}

export default NavBar
