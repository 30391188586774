import gql from 'graphql-tag'

export default gql`
    mutation AddAsset(
        $name: String!
        $tag: String!
        $value: Float
        $inUse: Boolean!
        $notes: String
        $purchaseDate: Date
        $warrantyUntil: Date
        $vendorId: Int
        $usedById: Int
        $updatedBy: String
    ) {
        addAsset(
            assetInput: {
                name: $name
                tag: $tag
                value: $value
                inUse: $inUse
                notes: $notes
                purchaseDate: $purchaseDate
                warrantyUntil: $warrantyUntil
                vendorId: $vendorId
                usedById: $usedById
                updatedBy: $updatedBy
            }
        ) {
            name
            tag
            value
            inUse
            notes
            purchaseDate
            warrantyUntil
            vendor {
                id
            }
            usedBy {
                id
            }
            updatedBy
        }
    }
`
