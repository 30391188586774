import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../react-auth0-spa'
import GetRoles from '../components/getRoles'
import { Button, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    errorMessage: {
        color: 'red',
        fontSize: '1rem',
        textAlign: 'center',
        marginTop: '1rem',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    Button: {
        marginTop: '1rem',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
}))

const PrivateRoute = ({ component: Component, path, roles, ...rest }) => {
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0()
    const reqRoles = GetRoles()
    const classes = useStyles()
    // search if the roles array contains the required roles
    useEffect(() => {
        if (loading || isAuthenticated) {
            return
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: { targetUrl: window.location.pathname },
            })
        }
        fn()
    }, [loading, isAuthenticated, loginWithRedirect, path])

    const render = (props) => {
        if (isAuthenticated === true) {
            if (roles && !reqRoles.some((role) => roles?.includes(role))) {
                // return a message if the user is not authorized
                return (
                    <div className={classes.errorMessage}>
                        <p>
                            You are not authorized to view this page
                            <br /> If you believe this is an error, please
                            contact the administrator, else go back to the home
                            page
                        </p>
                        <Button
                            className={classes.Button}
                            component={Link}
                            variant="outlined"
                            color="primary"
                            to="/"
                        >
                            Go Home!
                        </Button>
                        <Button
                            className={classes.Button}
                            variant="outlined"
                            color="secondary"
                            onClick={() => window.location.reload()}
                        >
                            Try Again!
                        </Button>
                    </div>
                )
            }
            return <Component {...props} />
        }
    }
    return <Route path={path} render={render} {...rest} />
    // check if the route is restricted by role
}

export default PrivateRoute
