import gql from "graphql-tag";

const MarkNotifRead = gql`
mutation markNotificationRead($notificationid: Int!, $email: String!) {
    markNotificationRead(notificationid: $notificationid, email: $email) {
        id
    }
  }
  


`;
        
export default MarkNotifRead;