import gql from "graphql-tag";

export default gql`
query GETASSET($idInput: Int!){
  asset(id:$idInput) {
      id
      name
      tag
      value
      inUse
      notes
      usedBy{
          name
      }
      vendor{
          name
      }
    }
  }
`;
