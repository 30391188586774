import React, { Fragment } from 'react'
// import { useAuth0 } from "../react-auth0-spa";
import { AgGridReact } from '@ag-grid-community/react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import getCarData from './mutations/getCarData'
import { useQuery } from '@apollo/client'
import { parseISO, formatISO9075 } from 'date-fns'
import Numeral from 'numeral'
import { Button, IconButton, Tooltip, Modal } from '@material-ui/core'
import ReplayTwoToneIcon from '@material-ui/icons/ReplayTwoTone'
import { Map as MapIcon, PieChart as BCIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'
import GetRoles from '../components/getRoles'
import Graph from '../components/graph'
import {
    onPageLoadSuccess
} from '../utils/toast'
import { ToastContainer } from 'react-toastify'

const createdAtFormatter = (params) => {
    // score the date on the basis of how recent it was. If the date is today, give it green, if yesterday, yellow, if more than 2 days ago, red.
    const date = parseISO(params.value)
    const today = new Date()
    const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000)
    const twoDaysAgo = new Date(today.getTime() - 48 * 60 * 60 * 1000)
    const threeDaysAgo = new Date(today.getTime() - 72 * 60 * 60 * 1000)

    if (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
    ) {
        return <span style={{ color: 'green' }}>{formatISO9075(date)}</span>
    } else if (
        date.getFullYear() === yesterday.getFullYear() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getDate() === yesterday.getDate()
    )
     {
        return <span style={{ color: 'orange' }}>{formatISO9075(date)}</span>
    } else if (
        date.getFullYear() === twoDaysAgo.getFullYear() &&
        date.getMonth() === twoDaysAgo.getMonth() &&
        date.getDate() === twoDaysAgo.getDate()
    ) {
        return <span style={{ color: 'amber' }}>{formatISO9075(date)}</span>
    }
     else if (
        date.getFullYear() === threeDaysAgo.getFullYear() &&
        date.getMonth() === threeDaysAgo.getMonth() &&
        date.getDate() === threeDaysAgo.getDate()
    ) {
        return <span style={{ color: 'red' }}>{formatISO9075(date)}</span>
    } else {
        return <span>{formatISO9075(date)}</span>
    }
}
const dayDateFormatter = (params) => {
    return params.value
        ? formatISO9075(parseISO(params.value), {
              representation: 'date',
          })
        : null
}
const numFormatter = (params) => {
    return params.value ? Numeral(params.value).format('0.0a') + ' Miles' : null
}

const ReturnMilage = () => {
    const { error, data:carData, refetch } = useQuery(getCarData, {
        // pollInterval: 5000,
    })
    const [data, setData] = React.useState([])
    const [modalOpen, setModalOpen] = React.useState(false)
    const [pieModalOpen, setPieModalOpen] = React.useState(false)
    const [isMobile, setIsMobile] = React.useState(false)
    const roles = GetRoles()

    const getDevice = () => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    React.useEffect(() => {
        getDevice()
        window.addEventListener('resize', getDevice)
        return () => window.removeEventListener('resize', getDevice)
    }, [])

    React.useEffect(() => {
        if (carData) {
            setData(carData)
            onPageLoadSuccess()
        }

    }, [carData])


    const xDaysLate = (params) => {
        const { value } = params
        const date = parseISO(value)
        const today = new Date()
        const diff = today - date
        const daysLate = Math.floor(diff / (1000 * 60 * 60 * 24))
        return `${daysLate} Days Late`
    }

    if (error)
        return (
            <div>
                <Tooltip title="Refresh Grid Data" onClick={() => refetch()}>
                    <IconButton alt="Reload the Grid" aria-label="RefetchQuery">
                        <ReplayTwoToneIcon  />
                    </IconButton>
                </Tooltip>
            </div>
        )
    const teslaDataUnwrapped = data?.vehicles?.map((vehicle) => ({
        id: vehicle.id,
        name: vehicle.name,
        vin: vehicle.vin,
        vrn: vehicle.vrn,
        model: vehicle.model,
        drivenBy: vehicle.usedBy ? vehicle.usedBy.name : null,
        drivenByTitle: vehicle.usedBy ? vehicle.usedBy.title : null,
        createdAt: vehicle.createdAt,
        updatedAt: vehicle.updatedAt,
        currentMileage: vehicle.latestData
            ? vehicle.latestData.mileage
            : undefined,
        dayDiff: vehicle.latestData
            ? `Up ${vehicle.latestData.dayDiff} miles since last reading`
            : undefined,
        longitude: vehicle.latestData
            ? vehicle.latestData.longitude
            : undefined,
        latitude: vehicle.latestData ? vehicle.latestData.latitude : undefined,
        hd: vehicle.spec
            ? vehicle.spec.rhd === true
                ? 'Right Hand Drive'
                : 'Left Hand Drive'
            : '',
        extColor: vehicle.spec
            ? vehicle.spec.extColor
                ? vehicle.spec.extColor
                : undefined
            : '',
        extTrim: vehicle.spec
            ? vehicle.spec.extTrim
                ? vehicle.spec.extTrim
                : undefined
            : '',
        wheelType: vehicle.spec
            ? vehicle.spec.wheelType
                ? vehicle.spec.wheelType
                : undefined
            : '',
        intTrim: vehicle.spec
            ? vehicle.spec.intTrim
                ? vehicle.spec.intTrim
                : undefined
            : '',
        carType: vehicle.spec
            ? vehicle.spec.carType
                ? vehicle.spec.carType
                : undefined
            : '',
        specLevel: vehicle.spec
            ? vehicle.spec.specLevel
                ? vehicle.spec.specLevel
                : undefined
            : '',
        chargePortType: vehicle.spec
            ? vehicle.spec.chargePortType
                ? vehicle.spec.chargePortType
                : undefined
            : '',
        autopilotType: vehicle.spec
            ? vehicle.spec.autopilotType
                ? vehicle.spec.autopilotType
                : undefined
            : '',
        hasAirSuspension: vehicle.spec
            ? vehicle.spec.hasLudicrousMode === true
                ? 'Air Suspension'
                : 'No Air Suspension'
            : '',
        hasLudicrousMode: vehicle.spec
            ? vehicle.spec.hasLudicrousMode === true
                ? 'Ludicrous Mode'
                : 'Standard'
            : '',
        performancePackage: vehicle.spec
            ? vehicle.spec.performancePackage
                ? vehicle.spec.performancePackage
                : undefined
            : '',
        rearSeatHeaters: vehicle.spec
            ? JSON.parse(vehicle.spec.rearSeatHeaters)
            : undefined,
        roofColor: vehicle.spec
            ? vehicle.spec.roofColor
                ? vehicle.spec.roofColor
                : undefined
            : '',
        spoilerType: vehicle.spec
            ? vehicle.spec.spoilerType
                ? vehicle.spec.spoilerType
                : undefined
            : '',
        timestamp: vehicle.spec
            ? vehicle.spec.timestamp
                ? vehicle.spec.timestamp
                : undefined
            : '',
        sum: vehicle.lastMonthData ? vehicle.lastMonthData.sum : undefined,
        dateStart: vehicle.lastMonthData
            ? vehicle.lastMonthData.dateStart
            : undefined,
        dateEnd: vehicle.lastMonthData
            ? vehicle.lastMonthData.dateEnd
            : undefined,
        lmdID: vehicle.lastMonthData ? vehicle.lastMonthData.id : undefined,
    }))

  
    // create an array of objects with the longitude and latitude, and id of the car, but filter out any cars that don't have a location
    const teslaArr = teslaDataUnwrapped?.filter((car) => car.longitude && car.latitude)?.map((car) => ({
            id: car.id,
            longitude: car.longitude,
            latitude: car.latitude,
            currentMileage: car.currentMileage,
            name: car.name,
            dayDiff: car.dayDiff,
            drivenBy: car.drivenBy,
            model: car.model,
            vrn: car.vrn,
        }))

    // extract the currentMileage from the data
    const currentMileage = teslaDataUnwrapped?.map(
        (tesla) => tesla.currentMileage
    )
    // loop through the currentMileage and and drop the null values
    const currentMileageFiltered = currentMileage?.filter(
        (milage) => milage !== undefined
    )
    const currentMileageSum = currentMileageFiltered?.reduce((a, b) => a + b, 0)
    // reduce the currentMileageSum to 2 decimal places
    const currentMileageSumRounded = Numeral(currentMileageSum).format('0.00a')
    const totalString = `${currentMileageSumRounded} Miles from a total of ${currentMileageFiltered?.length} vehicles`
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            hide: true,
            cellRendererFramework: (params) => {
                return (
                    <strong>

                            <p>
                            {params.value}
                            </p>
                        
                    </strong>
                )
            },
        },
        {
            field: 'name',
            headerName: 'Car Name:',
            tooltipField: 'vin',
            width: 110,
        },
        { field: 'model', headerName: 'Model', width: 80 },
        { field: 'vrn', headerName: 'Plate', width: 40 },
        {
            field: 'dayDiff',
            headerName: 'dayDiff',
            width: 80,
            hide: true,
            cellRendererFramework: (params) => {
                return <div>+{params.value} in 24H</div>
            },
        },
        // {
        //   field: 'carVin',
        //   headerName: 'VIN:',
        //   width: 220,
        //   rowGroup: true,
        //   hide: true,
        // },
        {
            field: 'updatedAt',
            headerName: 'Data Retrieved On',
            sort: 'desc',
            cellRendererFramework: (params) => {
                return (
                    <div>
                        <Tooltip title={xDaysLate(params)}>
                            {createdAtFormatter(params)}
                        </Tooltip>
                    </div>
                )
            },
        },
        {
            field: 'currentMileage',
            headerName: 'Current Mileage:',
            width: 120,
            valueFormatter: numFormatter,
            tooltipField: 'dayDiff',
            // onCellClicked: (cellClickHandler),
            cellRendererFramework: (params) => {
                const latitude = params ? params.data.latitude : null
                const longitude = params ? params.data.longitude : null
                const mapLnk =
                    'https://www.google.com/maps/search/?api=1&query='
                const locationParams = longitude + ',' + latitude
                const fullLink = mapLnk + locationParams
                return (
                    <a target="_blank" rel="noreferrer" href={fullLink}>
                        {params.valueFormatted}
                    </a>
                )
            },
        },
        {
            field: 'latitude',
            hide: true,
        },
        {
            field: 'longitude',
            hide: true,
        },
        {
            field: 'createdAt',
            headerName: 'Inducted On',
            valueFormatter: dayDateFormatter,
        },
        { field: 'drivenBy', tooltipField: 'drivenByTitle' },
    ]

    return (
        
        <Fragment>
                    <ToastContainer/>

            <div
                className="ag-theme-material"
                style={{
                    minHeight: '500px',
                    height: '80vh',
                    width: 'auto',
                    resizable: true,
                    fontSize: '12px',
                }}
            >
                <Tooltip title="If a vehicle is yet to have it's reading taken, it is discounted.">
                    <h3 style={{ textAlign: 'center' }}>{totalString + '*'}</h3>
                </Tooltip>
                <Tooltip title="Refresh Grid Data" onClick={() => refetch()}>
                    <IconButton alt="Reload the Grid"  aria-label="RefetchQuery">
                        <ReplayTwoToneIcon  />
                    </IconButton>
                </Tooltip>
                <IconButton
                    alt="Display the Tesla Map"
                    aria-label="TeslaMap"
                    disabled={!roles.includes('superadmin')}
                    style={{
                        // place it to the right of the screen
                        position: 'absolute',
                        right: '15px',
                    }}
                    onClick={() => {
                        setModalOpen(true)
                    }}
                >
                    <Tooltip title="Display the Tesla Map">
                        <MapIcon
                            
                        />
                    </Tooltip>
                </IconButton>
                <IconButton
                    alt="Display the Pie Chart"
                    aria-label="PieChart"
                    disabled={!roles.includes('superadmin')}
                    style={{
                        // place it to the right of the screen
                        position: 'absolute',
                        right: '75px',
                    }}
                    onClick={() => {
                        setPieModalOpen(true)
                    }}
                >
                    <Tooltip title="Display the Tesla Pie Chart" >
                        <BCIcon
                            
                        />
                    </Tooltip>
                </IconButton>
                <AgGridReact
                    rowData={teslaDataUnwrapped}
                    columnDefs={columns}
                    modules={[ClientSideRowModelModule, RowGroupingModule]}
                    //paginationAutoPageSize={true}
                    pagination={true}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 100,
                        sortable: true,
                        resizable: true,
                        suppressSizeToFit: true,
                    }}
                    autoGroupColumnDef={{ minWidth: 200, minHeight: 500 }}
                    groupMultiAutoColumn={true}
                    enableRangeSelection={true}
                    animateRows={true}
                />
                <Tooltip title="Refresh Grid Data" onClick={() => refetch()}>
                    <IconButton alt="Reload the Grid"  aria-label="RefetchQuery">
                        {' '}
                        <ReplayTwoToneIcon  />
                    </IconButton>
                </Tooltip>
            </div>
            {modalOpen && (
                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div>
                        <div>
                            <Map
                                center={[55.3781, -3.436]}
                                zoom={5}
                                style={{
                                    // place the map in the top center of the modal
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    height: '80%',
                                    width: '80%',
                                    zIndex: 1,
                                }}
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />
                                {teslaArr &&
                                    teslaArr.map((vehicle, index) => {
                                        return (
                                            <Marker
                                                key={index}
                                                position={[
                                                    vehicle?.longitude,
                                                    vehicle?.latitude,
                                                ]}
                                            >
                                                <Popup>
                                                    <p>
                                                        {vehicle.name} <br />
                                                        {vehicle.model}
                                                    </p>
                                                    <p>
                                                        {vehicle.currentMileage !==
                                                            undefined &&
                                                            vehicle.currentMileage +
                                                                ' miles'}
                                                    </p>
                                                    <p>
                                                        {vehicle.vrn} <br />
                                                        {vehicle.drivenBy}
                                                    </p>
                                                    <p>
                                                        {vehicle.currentMileage !==
                                                            undefined &&
                                                            vehicle.dayDiff}
                                                    </p>
                                                </Popup>
                                            </Marker>
                                        )
                                    })}
                            </Map>
                        </div>
                    </div>
                </Modal>
            )}
            {pieModalOpen && (
                <Modal style={{height: '100vh'}}
                    open={pieModalOpen}
                    onClose={() => setPieModalOpen(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div>
                        <div  >
                            <Graph/>
                        </div>
                    </div>
                </Modal>
            )}
        </Fragment>
    )
}
export default ReturnMilage
