import React from 'react'
import {
    Drawer,
    IconButton,
    Divider,
    List,
    useTheme,
    makeStyles,
} from '@material-ui/core'
import {
    AddCircle,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Home as HomeIcon,
    MenuBookSharp,
    PersonOutlineOutlined,
} from '@material-ui/icons'
import BugReportIcon from '@material-ui/icons/BugReport'
import BadgeIcon from '@mui/icons-material/Badge';
import { useRecoilState } from 'recoil'
import ListItemLink from './ListItemLink'
import { drawerOpenState } from '../atoms'
import { Link } from 'react-router-dom'
import GetRoles from './getRoles'
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        // shine each item in the drawer and give it a defined outline
        '& .MuiListItem-root': {
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
                borderRadius: '4px',
                outline: `5px solid ${theme.palette.secondary.main}`,
                transition: 'outline 0.5s',
            },
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
}))

const NavDrawer = () => {
    const classes = useStyles()
    const theme = useTheme()
    const [drawerOpen, setDrawerOpen] = useRecoilState(drawerOpenState)
    const roles = GetRoles()
    const requiredRoles = ['localdeveloper']

    const forDebug = () => {
        // if user has one of the required roles, show the header
        if (roles?.some((role) => requiredRoles?.includes(role))) {
            return (
                <div className={classes.drawerHeader}>
                    <Link
                        to="/views/employeePortal"
                        onClick={handleDrawerClose}
                    >
                        <PersonOutlineOutlined />
                    </Link>
                    <Link to="/tools/debug" onClick={handleDrawerClose}>
                        <BugReportIcon />
                    </Link>
                </div>
            )
        }
    }

    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }

    return (

        <Drawer
            className={classes.drawer}
            anchor="left"
            open={drawerOpen}
            onBackdropClick={handleDrawerClose}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            {forDebug()}

            <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? (
                        <ChevronLeftIcon />
                    ) : (
                        <ChevronRightIcon />
                    )}
                </IconButton>
            </div>
            {/* <div className={classes.drawerHeader}>
        <Link to='/views/employeemgr' onClick={handleDrawerClose}>
          <PersonOutlineOutlined />
        </Link>
        <IconButton onClick={handleDrawerClose}></IconButton>
      </div> */}
            <Divider />
            <div
                role="presentation"
                onClick={handleDrawerClose}
                onKeyDown={handleDrawerClose}
            >
                <List>
                    <ListItemLink to="/" primary="Home" icon={<HomeIcon />} />
                    <ListItemLink
                        to="/views/AddAsset"
                        primary="Add Entry"
                        icon={<AddCircle />}
                    />
                    <ListItemLink
                        to="/views/table"
                        primary="View Entries"
                        icon={<MenuBookSharp />}
                    />
                     <ListItemLink
                        to="/views/employees"
                        primary="View Employees"
                        icon={<BadgeIcon />}
                    />
                    <ListItemLink
                        to="/views/teslaTable"
                        primary="View Car Data"
                        icon={<ElectricCarIcon />}
                    />
                    <ListItemLink
                        to="/views/selfService"
                        primary="Self Service Ticketing"
                        icon={<ContactSupportIcon />}
                    />
                </List>
            </div>
        </Drawer>
    )
}

export default NavDrawer
