// import { format } from 'date-fns';
// import parseISO from 'date-fns/parseISO';
import React, { Fragment, useEffect } from 'react'
import { useAuth0 } from './react-auth0-spa'
import Map from 'react-leaflet/lib/Map'
import Marker from 'react-leaflet/lib/Marker'
import Popup from 'react-leaflet/lib/Popup'
import TileLayer from 'react-leaflet/lib/TileLayer'
import makeStyles  from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    map: {
        height: '50vh',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            height: '50vh',
        },
    },
}))

const Landing = () => {
    const { loading, user } = useAuth0()
    const lastLoggedIP = user
        ? user['https://joealexander.com/user_metadata'].ip
        : null

    const lastLoggedLocation = user
        ? user['https://joealexander.com/user_metadata'].geoip.city_name
        : null
    const lastLoggedLat = user
        ? user['https://joealexander.com/user_metadata'].geoip.latitude
        : null

    const lastLoggedLong = user
        ? user['https://joealexander.com/user_metadata'].geoip.longitude
        : null

    const classes = useStyles()
    const [zoom, setZoom] = React.useState(13)
    const [open, setOpen] = React.useState(false)
    const [position, setPosition] = React.useState([0, 0])
    useEffect(() => {
        setPosition([lastLoggedLat, lastLoggedLong])
    }, [lastLoggedLat, lastLoggedLong, user])

    if (loading || !user) {
        return <div>Loading...</div>
    }

    return (
        <Fragment>
            <div className={classes.Fragment}>
                <h1 align="center">
                    Hello, {user.name ? user.name : null} {user.previous_login}
                </h1>
                <h4 align="center">
                    The last login was from:
                    <a
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                        target="_blank"
                        rel="noreferrer"
                        href={
                            'https://whatismyipaddress.com/ip/' + lastLoggedIP
                        }
                    >
                        {lastLoggedIP}
                    </a>
                    <Map
                        id="map"
                        center={position}
                        zoom={zoom}
                        className={classes.map}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {!open && (
                            <Marker
                                position={position}
                                onClick={() => {
                                    setZoom(18)
                                    setPosition(position)
                                    setOpen(true)
                                }}
                            ></Marker>
                        )}
                        {open && (
                            <Popup
                                position={position}
                                open={open}
                                onClose={() => {
                                    setOpen(!open)
                                    setZoom(13)
                                }}
                            >
                                <div>
                                    <h2>{lastLoggedLocation}</h2>
                                    <p>Latitude: {lastLoggedLat}</p>
                                    <p>Longitude: {lastLoggedLong}</p>
                                    <p>IP: {lastLoggedIP} </p>
                                </div>
                            </Popup>
                        )}
                    </Map>
                    <br />
                </h4>
                <h5 align="center">
                    In the <b>{lastLoggedLocation}</b> area.
                </h5>

                <br />
                <Fragment>
                    <h6 align="center">
                        If you don't recognise this, contact your administrator
                        to secure your account
                    </h6>
                </Fragment>
            </div>
        </Fragment>
    )
}

export default Landing
