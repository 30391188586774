import notifications from './gql/notifications'
import { useQuery } from '@apollo/client'


const GetNotifs = (ID) => {

        // when and only when the ID has been set, run the query

        const { data } = useQuery(notifications, {
                variables: { email: ID },
                skip: ID === null,
                displayName: "GetNotifs",
                pollInterval: 120000
                
        })
        return data?.notifications

}

export default GetNotifs
