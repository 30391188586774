import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Menu, MenuItem, Snackbar } from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'
import { useAuth0 } from '../react-auth0-spa'
import axios from 'axios'
import { Alert } from '@material-ui/lab'
import { Switch, useDarkreader } from 'react-darkreader'
import { default as data } from '../../package.json'



const getEnvironment = () => {
    if (process.env.NODE_ENV === 'development') {
        return 'Dev🥸'
    } else {
        return 'Prod🚀'
    }
}


const NavProfile = () => {
    const { logout } = useAuth0()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const profileOpen = Boolean(anchorEl)
    const [alertOpen, setAlertOpen] = React.useState(false)
    const { user } = useAuth0()

    // check what the user's system theme is
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
    // dark mode toggle which uses local storage to save the theme
    let [isDark, { toggle }] = useDarkreader(false, {
        storage: true,
        storageKey: 'lightMode'
    })


    useEffect(() => {
        // check if we have a theme in local storage
        const lightMode = sessionStorage.getItem('lightMode')
        if (lightMode === null || lightMode === undefined) {
            // if we don't, set the theme to the system theme
            if (prefersDarkMode) {
                toggle()
                sessionStorage.setItem('lightMode', 'false')
            } else {
                sessionStorage.setItem('lightMode', 'true')
            }
        }
        // if we do, set the theme to the current theme
        if (lightMode === 'false') {
            toggle()
        }
    }, [])



    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleProfileClose = () => {
        setAnchorEl(null)
    }


    const changePassword = React.useCallback(async () => {
        // confirm with the user that they want to change their password
        const result = window.confirm(
            'This will send you an email to reset your password. Are you sure you want to continue?'
        )
        if (result) {
            try {
                // close profile menu
                handleProfileClose()
                // send password reset request
                const { status } = await axios.post(
                    'https://joealexander.eu.auth0.com/dbconnections/change_password',
                    {
                        client_id: 'zFwEP76G60f3uG8sw9Tcs8hvom4I1s9u',
                        email: user.email,
                        connection: 'Username-Password-Authentication',
                    }
                )
                // check status & display success message
                if (status === 200) {
                    setAlertOpen(true)
                }
            } catch (error) {
                console.error(error)
            }
        }
    }, [user])

    const handleAlertClose = () => {
        alert("closed")
        setAlertOpen(false)
    }
    const handleDarkToggle = () => {
        // toggle dark mode and save to local storage
        sessionStorage.setItem('lightMode', isDark)
        toggle()
    }
    const handleLogout = () => {
        const result = window.confirm('Are you sure you want to logout?')
        if (result) {
            sessionStorage.clear()
            sessionStorage.clear()
            logout()
        }
    }

    return (
        <div>

            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={profileOpen}
                onClose={handleProfileClose}
            >
                <MenuItem>
                    <Switch checked={isDark} onChange={handleDarkToggle} />
                </MenuItem>
                <MenuItem component={Link} to="/profile">
                    Profile
                </MenuItem>
                <MenuItem onClick={changePassword}>Change Password</MenuItem>
                <MenuItem onClick={() => handleLogout()}>Log out</MenuItem>
                <MenuItem disabled> v.{data.version}: {getEnvironment()}</MenuItem>
            </Menu>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
            >
                <Alert
                    onClose={handleAlertClose}
                    elevation={6}
                    variant="filled"
                >
                    We've just sent you an email to reset your password.
                </Alert>
            </Snackbar>
        </div>
    )
}

export default NavProfile
