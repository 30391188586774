import React from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Auth0Provider } from './react-auth0-spa'
import history from './utils/history'
// A function that routes the user to the right place
// after login

const onRedirectCallback = (appState) => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    )
}

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <Auth0Provider
                domain={process.env.REACT_APP_DOMAIN} // TODO: set this
                client_id={process.env.REACT_APP_CLIENT_ID}
                // audience={process.env.REACT_APP_AUTH0_AUDIENCE}
                audience={process.env.REACT_APP_AUDIENCE}
                redirect_uri={window.location.origin}
                onRedirectCallback={onRedirectCallback}
                scope="openid profile email"
            >
                <App />
            </Auth0Provider>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
