import { useAuth0 } from '../react-auth0-spa'

const GetRoles = () => {
    const { user } = useAuth0()
    const roles = user
        ? user['https://joealexander.com/app_metadata']?.roles
        : []
    // return the roles array
    return roles
    // return ['admin']
}
export default GetRoles
