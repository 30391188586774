import React from 'react'
import GETASSET from './mutations/getAsset'
import UpdateAsset from './mutations/updateAsset'
import { Link, useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { Autocomplete } from '@material-ui/lab'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import {
    Button,
    Container,
    CssBaseline,
    Grid,
    makeStyles
} from '@material-ui/core'
import GetUser from '../components/getUser'
import { toast, ToastContainer } from 'react-toastify'
import gql from 'graphql-tag'
import { onPageLoadSuccess } from '../utils/toast'


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

const employees = gql`
    query GetAllEmployees {
        employees {
            id
            name
        }
    }
`
const vendors = gql`
query GetAllVendors {
    vendors{
        id
        name
    }
}
`
const EditAsset = () => {
    const { Aid } = useParams()
    const classes = useStyles()
    const currentUser = GetUser()
    const [data, setData] = React.useState({})
    const [employeeList, setEmployeeList] = React.useState([])
    const [vendorList, setVendorList] = React.useState([])



    const { loading, error, data:assetData, refetch } = useQuery(GETASSET, {
        variables: { idInput: parseFloat(Aid) },
        fetchPolicy: 'network-only',
    })
    const [updateAsset] = useMutation(UpdateAsset)
    const { data: employeeData, refetch: employeeRF } = useQuery(employees)
    const { data: vendorData, refetch: vendorRF } = useQuery(vendors)

    const refreshAll = () => {
        toast.promise(
        refetch(),
        {
            pending: 'Refetching Asset Data...',
            success: 'Asset Data Refetched!',
            error: 'Something went wrong!',
        }
    )
        toast.promise(
        employeeRF()
        ,
        {
            pending: 'Refetching Employee Data...',
            success: 'Employee Data Refetched!',
            error: 'Something went wrong!',
        }
    )
        toast.promise(
        vendorRF()
        ,
        {
            pending: 'Refetching Vendor Data...',
            success: 'Vendor Data Refetched!',
            error: 'Something went wrong!'
        }
    )

    }

    React.useEffect(() => {
        if (assetData) {
            setData(assetData)
            onPageLoadSuccess()
        }
    }, [assetData])

    React.useEffect(() => {
        
        if (employeeData) {
            const employeeSet = [
                ...new Set(
                    employeeData? employeeData?.employees.map((employee) => {
                        return employee.name
                    })
                    : []
                )
            ]
            setEmployeeList(employeeSet)
        }
        if (vendorData) {
            const vendorSet = [
                ...new Set(
                    vendorData? vendorData?.vendors.map((vendor) => {
                        return vendor.name
                    })
                    : []
                )
            ]
            setVendorList(vendorSet)
        }
    }, [employeeData, vendorData, employeeRF, vendorRF])


    const findEmployee = (employeeName) => {
        try{
        return employeeData?.employees.find((employee) => {
            return employee.name === employeeName
        })
    } catch (e) {
        console.log(e)

    }}
    const findVendor = (vendorName) => {
        try{
        return vendorData?.vendors.find((vendor) => {
            return vendor.name === vendorName
        })
    } catch (e) {
        console.log(e)

    }
    }
    const [employee, setEmployee] = React.useState(null)
    const [vendor, setVendor] = React.useState(null)
    React.useEffect(() => {
        if (data?.asset?.usedBy?.name) {
            setEmployee(findEmployee(data?.asset?.usedBy?.name)?.id)
        }
    }, [data?.asset?.usedBy?.name])
    React.useEffect(() => {
        if (data?.asset?.vendor?.name) {
            setVendor(findVendor(data?.asset?.vendor?.name)?.id)
        }} , [data?.asset?.vendor?.name])


    


    const HandleEditAsset = (data) => {
        data.preventDefault()
        toast.promise(
        updateAsset(
            {
            variables: {
                id: parseFloat(Aid),
                name: data.target[1].value,
                tag: data.target[3].value,
                value: parseFloat(data.target[5].value),
                inUse: JSON.parse(data.target[7].value),
                notes: data.target[9].value,
                usedById: employee ? employee : null,
                vendorId: vendor ? vendor : null,
                updatedBy: currentUser.toString(),
            },
            
        }),

        {
            pending: 'Updating Asset...',
            success: 'Asset Updated!',
            error: 'Something went wrong!',
        }
    )
            
            .then(refetch)
            .then(refetch)
    }

    return (
        <Container component="main" width="80vw">
            <CssBaseline />
            {data?.asset && <Grid container spacing={2}>
                <div className={classes.paper}>
                    <ToastContainer
                    position='bottom-left'
                    
                    />
                    <Link to={() => '/views/table'}>
                        <Button fullWidth variant="contained" color="primary">
                            Back
                        </Button>
                    </Link>
                    < br />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={refreshAll}
                    >
                        Refetch Data
                    </Button>
                    <form
                        className={classes.form}
                        noValidate
                        onSubmit={HandleEditAsset}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="default"
                                    defaultValue={data?.asset?.id}
                                    hidden={true}
                                    readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    label="Asset Name"
                                    defaultValue={data?.asset?.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    label="Asset Tag"
                                    defaultValue={data?.asset?.tag}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="number"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    label="Asset Value"
                                    defaultValue={data?.asset?.value}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel id="demo-simple-select-label">
                                    Assigned?
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={data?.asset?.inUse}
                                    variant="outlined"
                                    required
                                    fullWidth
                                >
                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label="Notes"
                                    defaultValue={data?.asset?.notes}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <TextField
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label="Used By"
                                    defaultValue={data?.asset?.usedBy?.name}
                                /> */}
                                <Autocomplete
                                    id="combo-box-demo"
                                    fullWidth
                                    options={employeeList}
                                    getOptionLabel={(option) => option}
                                    defaultValue={data?.asset?.usedBy?.name}
                                   onChange={(event, newValue) => {
                                    try{
                                        setEmployee(findEmployee(newValue)?.id)
                                    }catch{
                                        console.log("No Employee Found")
                                    }
                                   }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Used By"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                {/* <TextField
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label="Vendor"
                                    defaultValue={data?.asset?.vendor?.name}
                                /> */}
                                 <Autocomplete
                                    id="combo-box-demo"
                                    fullWidth
                                    options={vendorList}
                                    getOptionLabel={(option) => option}
                                    defaultValue={data?.asset?.vendor?.name}
                                    onChange={(event, newValue) => {
                                        try{
                                            setVendor(findVendor(newValue)?.id)
                                        }catch{
                                            console.log("No Vendor Found")
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Vendor"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Button
                                disabled={(loading, error)}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Submit Changes
                            </Button>
                        </Grid>
                    </form>
                </div>
            </Grid>
} 
        </Container>
    )
}

export default EditAsset
