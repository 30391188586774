import gql from 'graphql-tag'

export default gql`
    {
        vehicles {
            id
            name
            vin
            vrn
            model
            lastMonthData {
                id
                sum
                dateStart
                dateEnd
            }
            usedBy {
                name
                title
            }
            createdAt
            updatedAt
            latestData {
                mileage
                longitude
                latitude
                dayDiff
            }
            spec {
                rhd
                extColor
                extTrim
                wheelType
                intTrim
                carType
                specLevel
                chargePortType
                autopilotType
                hasAirSuspension
                hasLudicrousMode
                performancePackage
                rearSeatHeaters
                roofColor
                spoilerType
                timestamp
            }
        }
    }
`
