import gql from "graphql-tag";

const NOTIFICATIONS = gql`
query notifications($email:String!) {
  notifications(email: $email) {
    id
    createdAt
    updatedAt
    subject
    description
    expiresAt
  }
}


`;
        
export default NOTIFICATIONS;