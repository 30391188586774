import { toast } from 'react-toastify'

const sucessMutToastID = 'onSuccessMutID'
const errorMutToastID = 'onErrorMutID'
const infoMutToastID = 'onInfoMutID'



const onSuccessNotify = () => {
    toast.success('Sucessfully Added', {
        toastId: sucessMutToastID,
        position: 'top-center',
    })
}

const onErrorNotify = () => {
    toast.error("The Toast isn't cooked, and you broke the simulation", {
        toastId: errorMutToastID,
        position: 'top-center',
    })
}

const onPageLoadSuccess = () => {
    toast.success('Data Loaded!', {
        toastId: sucessMutToastID,
        position: 'top-center',
    })
}

const onPageLoadError = () => {
    toast.error("Something isn't quite right. Please reload the page", {
        toastId: errorMutToastID,
        position: 'top-center',
    })
}

const onImageLoad = () => {
    toast.info('The vehicle image may take a moment... please wait', {
        toastId: infoMutToastID,
        position: 'top-center',
    })
}



export {
    onSuccessNotify,
    onErrorNotify,
    onPageLoadSuccess,
    onPageLoadError,
    onImageLoad,
}
