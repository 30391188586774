import React, { useState, useEffect } from 'react';
// import GetNotifs from './getNotifs';
import MarkNotifRead from "./gql/markNotifRead";
import { useMutation, useQuery } from "@apollo/client";
import './compCSS/notifications.css';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import notifications from './gql/notifications'
import { toast, ToastContainer } from 'react-toastify/dist'





const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),

  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    marginTop: '1rem',



  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: 'auto', // add this line
    width: 'auto', // and this line
    overflow: 'auto', // and this line
  },
}));

const handleTimestamp = (timestamp, onlyDate) => {
  const date = new Date(timestamp);
  if (onlyDate) {
    date.setHours(0, 0, 0, 0);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', };
    return date.toLocaleDateString(date, options);
  }
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', };
  return date.toLocaleDateString(date, options);
}

const NotificationsModal = () => {
  const [notificationsData, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [ID, setID] = useState(null);
  const classes = useStyles();
  const [markAsRead] = useMutation(MarkNotifRead);
  const notifs = useQuery(notifications, {
    variables: { email: ID },
    skip: ID === null,
    displayName: "GetNotifs",
    pollInterval: 120000
  })

  useEffect(() => {
    const email = sessionStorage.getItem('email');
    setID(email);
    setNotifications(null)
    const { data } = notifs;
    setNotifications(data?.notifications);


  }, [notifs]);

  const handleRefetch = () => {
    setTimeout(() => {
      notifs.refetch();
    setNotifications(null)
    const { data } = notifs;
    setNotifications(data?.notifications);
    }, 500);

  }

  const handleMarkAsRead = () => {
      toast.promise(markAsRead({
        variables: {
          notificationid: selectedNotification?.id,
          email: ID
        }
      }), {
        pending: `Marking '${selectedNotification?.subject}' as read...`,
        success: `Marked '${selectedNotification?.subject}' as read!`,
        error: `Error marking '${selectedNotification?.subject}' as read!`
      }
      )
      setShowModal(false);
      handleRefetch();


      
    

  }


  const toggleModal = () => {
    setShowModal(!showModal);
  }

  const selectNotification = (notification) => {
    setSelectedNotification(notification);
    setShowModal(true);
  }
// if the created At is today, slight highlight in green
  const renderNotificationTable = () => {
    
    return (
      <table className="notification-table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Updated At</th>
          </tr>
        </thead>
        {/* <tbody>
          {notificationsData?.map((notification) => (
            <tr key={notification?.id} onClick={() => selectNotification(notification)}>
              <td>{notification?.subject}</td>
              <td>{handleTimestamp(notification?.updatedAt)}</td>
            </tr>
          ))}
        </tbody> */}
        <tbody >
        {notificationsData?.map((notification) => {
  const createdAt = new Date(notification?.createdAt);
  const updatedAt = new Date(notification?.updatedAt);
  const today = new Date();
  let className;
  if (handleTimestamp(createdAt, true) === handleTimestamp(today, true)) {
    className = 'created-today';}
    else if (handleTimestamp(updatedAt, true) === handleTimestamp(today, true)) {
      className = 'updated-today';
  }
  return (
    <tr
      key={
        notification?.id
      }
      onClick={() => selectNotification(notification)}
      className={className}
    >
      <td>{notification?.subject}</td>
      <td>{handleTimestamp(notification?.updatedAt)}</td>
    </tr>
  );
})}





        </tbody>
      </table>
    );
  }

  const renderSelectedNotificationModal = () => {
    if (!selectedNotification) {
      return null;
    }

    return (
      <Modal
        open={showModal}
        onClose={toggleModal}
        aria-labelledby="selected-notification-modal"
        aria-describedby="selected-notification-modal-description"
        className={classes.modal}
      >
        <div className={classes.modalContent}>
          <h2 id="selected-notification-modal">{selectedNotification.subject}</h2>
          {/* <p id="selected-notification-modal-description">{selectedNotification.description}</p> */}
          {/* the description should be formatted as html as the sql has tags */}
          <div dangerouslySetInnerHTML={{ __html: selectedNotification.description }} />
          <br />
          <p>Last updated at: {handleTimestamp(selectedNotification?.updatedAt)}</p>
          <Button variant="contained" color="secondary" className={classes.button} onClick={toggleModal}>
            Close
          </Button>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleMarkAsRead}>
            Mark as Read
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <div className="notification-modal">
      <ToastContainer
        position='bottom-left'
      />
      {renderNotificationTable()}
      {renderSelectedNotificationModal()}
    </div>
  );
}

export default NotificationsModal;
