import React from 'react'
import { useFormik } from 'formik'
import { useMutation } from '@apollo/client'
import addentrymutation from './mutations/addEntryMutation'

// create a formik form asking for name as a string, asset tags string, value as float and inuse as boolean
// submit the form and display the result in the console

const AddAssetFormikRewrite = () => {
    const [addEntry] = useMutation(addentrymutation)
    const handleSubmit = (values) => {
        addEntry({
            variables: {
                name: values.name,
                tag: values.tag,
                value: values.value,
                inUse: values.inUse,
            },
        })
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            tag: '',
            value: '',
            inUse: false,
            notes: '',
            purchaseDate: '',
            warrentyUntil: '',
            vendorId: '',
            usedById: '',
        },
        onSubmit: handleSubmit,
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <input
                type="text"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
            />
            <input
                type="text"
                name="tag"
                onChange={formik.handleChange}
                value={formik.values.tag}
            />
            <input
                type="number"
                name="value"
                onChange={formik.handleChange}
                value={formik.values.value}
            />
            <input
                type="checkbox"
                name="inUse"
                onChange={formik.handleChange}
                value={formik.values.inUse}
            />
            <input
                type="text"
                name="notes"
                onChange={formik.handleChange}
                value={formik.values.notes}
            />
            <button type="submit">Submit</button>
        </form>
    )
}

export default AddAssetFormikRewrite
