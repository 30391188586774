import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'





const Copyright = () => {


    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link
                color="inherit"
                target="_blank"
                rel="noreferrer"
                href="https://www.hi-levelmezzanines.co.uk/"
            >
                Hi-Level Design Ltd {new Date().getFullYear()}
            </Link>
            {'.'}

        </Typography>
    )
}
export default Copyright
