import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Snackbar from '@mui/material/Snackbar'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/client'
import { CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'

const PULLEMPDATA = gql`
    {
        employees {
            id
            name
            title
            initials
            email
            createdAt
            updatedAt
            vehicle {
                name
            }
            assets {
                id
                tag
                name
            }
            active
        }
    }
`
const UpdateEmpData = gql`
    mutation UpdateEmpData(
        $id: Int!
        $name: String
        $title: String
        $email: EmailAddress
        $active: Boolean
    ) {
        updateEmployee(
            id: $id
            employeeInput: {
                name: $name
                title: $title
                email: $email
                active: $active
            }
        ) {
            id
            name
            title
            initials
            email
            active
        }
    }
`
const delEmp = gql`
    mutation delEmp($id: Int!) {
        deleteEmployee(id: $id) 
    }
`

export default function Employees() {
    //    display the list of employees, and give a method of editing them in a dialog box
    const [open, setOpen] = React.useState(false)
    const [snackbar, setSnackbar] = React.useState(false)
    const [snackbarMessage, setSnackbarMessage] = React.useState('')
    const [snackbarSeverity, setSnackbarSeverity] = React.useState('info')
    const [empData, setEmpData] = React.useState([])
    const [empDataEdit, setEmpDataEdit] = React.useState({})
    const [pollInterval, setPollInterval] = React.useState(36000000)
    const [
        updateEmpData
    ] = useMutation(UpdateEmpData)
    const [
        delEmpData
    ] = useMutation(delEmp)

    const { loading, data, refetch: dataRefetch } = useQuery(
        PULLEMPDATA,
        {
            pollInterval: pollInterval,
        }
    )

    const handleEditClick = React.useCallback((params) => {
        setOpen(true)
        setEmpDataEdit(params.row)
    }, [])

    const handleClose = React.useCallback(() => {
        setOpen(false)
    }, [])

    

    

    // create a function to handle the changing of the poll interval. The users will be given options of 5,10,15,30,60 seconds or off
    const handlePollIntervalChange = React.useCallback((event) => {
        setPollInterval(event.target.value)
    }, [])

    const loadingSpinner = React.useMemo(() => {
        return (
            <div style={{ display: 'flex', height: 400, width: '100%' }}>
                <div
                    style={{
                        margin: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2" color="text.secondary">
                            Loading...
                        </Typography>
                    </Box>
                </div>
            </div>
        )
    }, [])





    const handleSave = React.useCallback(() => {
        updateEmpData({
            variables: {
                id: empDataEdit.id,
                name: empDataEdit.name,
                title: empDataEdit.title,
                email: empDataEdit.email,
                active: empDataEdit.active,
            },
        })
            .then(() => {
                setSnackbar(true)
                setSnackbarMessage('Employee Updated')
                setSnackbarSeverity('success')
                setOpen(false)
            })
            .catch(() => {
                setSnackbar(true)
                setSnackbarMessage('Error Updating Employee')
                setSnackbarSeverity('error')
                setOpen(false)
            })
            .finally(() => {
                dataRefetch()
            })
    }, [empDataEdit, updateEmpData, dataRefetch])

    const handleDelete = React.useCallback(() => {
        let EmployeeMarkedForDeletion = empDataEdit.name
        const result = window.confirm(`Delete ${EmployeeMarkedForDeletion}? This action cannot be undone.`)
        if (result) {
            delEmpData({
                variables: {
                    id: empDataEdit.id,
                },
            })
                .then(() => {
                    setSnackbar(true)
                    setSnackbarMessage('Employee Deleted')
                    setSnackbarSeverity('success')
                    setOpen(false)
                })
                .catch((e) => {
                    setSnackbar(true)
                    setSnackbarMessage(`Error Deleting Employee:  ${e}`)
                    setSnackbarSeverity('error')
                    setOpen(false)
                }
                )
                .finally(() => {
                    dataRefetch()
                }
                )
        }

    }, [empDataEdit, delEmpData, dataRefetch])

    const handleSnackbarClose = React.useCallback(() => {
        setSnackbar(false)
    }, [])

    React.useEffect(() => {
        if (data) {
            setEmpData(data.employees)
        }
    }, [data])

    const columns = [
        { field: 'id', headerName: 'ID', width: 70, hide: true },
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'title', headerName: 'Title', width: 130 },
        { field: 'initials', headerName: 'Initials', width: 130 },
        { field: 'email', headerName: 'Email', width: 130 },
        { field: 'active', headerName: 'Active', width: 130, type: 'boolean' },
        {
            field: 'vehicle',
            headerName: 'Vehicle',
            width: 130,
            renderCell: (params) => {
                return params.row.vehicle?.name
            },
        },
        {
            field: 'assets',
            headerName: 'Assets',
            width: 130,
            filterable: false,
            renderCell: (params) => {
                // return parseInt(params.row.assets?.length)
                if (params.row.assets?.length > 0) {
                    return params?.row?.assets?.length
                } else {
                    return null
                }
            },
        },
    ]

    return (
        <div style={{ height: '80vh', width: '100%' }}>
            <div>
                <label htmlFor="pollInterval">Poll Interval</label>
                <select
                    name="pollInterval"
                    id="pollInterval"
                    onChange={handlePollIntervalChange}
                >
                    <option value={36000000}>Never</option>
                    <option value={5000}>5 Seconds</option>
                    <option value={10000}>10 Seconds</option>
                    <option value={15000}>15 Seconds</option>
                    <option value={30000}>30 Seconds</option>
                    <option value={60000}>60 Seconds</option>
                </select>
            </div>
            {loading && loadingSpinner}
            <DataGrid
                rows={empData}
                columns={columns}
                onRowDoubleClick={handleEditClick}
            />
            <Snackbar
                open={snackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Employee</DialogTitle>
                <DialogContent>
                    <div>
                        <div>
                            <label>
                                Name:
                                <input
                                    type="text"
                                    value={empDataEdit.name}
                                    onChange={(event) =>
                                        setEmpDataEdit({
                                            ...empDataEdit,
                                            name: event.target.value,
                                        })
                                    }
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Title:
                                <input
                                    type="text"
                                    value={empDataEdit.title}
                                    onChange={(event) =>
                                        setEmpDataEdit({
                                            ...empDataEdit,
                                            title: event.target.value,
                                        })
                                    }
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Email:
                                <input
                                    type="text"
                                    value={empDataEdit.email}
                                    onChange={(event) =>
                                        setEmpDataEdit({
                                            ...empDataEdit,
                                            email: event.target.value,
                                        })
                                    }
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Active:
                                {/* checkbox for true or fasle */}
                                <input
                                    type="checkbox"
                                    checked={empDataEdit.active}
                                    onChange={(event) =>
                                        setEmpDataEdit({
                                            ...empDataEdit,
                                            active: event.target.checked,
                                        })
                                    }
                                />
                            </label>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save</Button>
                    <Button color="error" onClick={handleDelete}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
