import React from 'react'
import { useLazyQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      background: theme.palette.primary.main,
      padding: theme.spacing(4),
      color: theme.palette.common.white,
      textAlign: 'center',
    },
    button: {
        margin: theme.spacing(2,0),
    }
  }));

const get_info = gql`
    query {
        info{
            about
            company
            version
        }
    }
`
const GetAPIAbout = ({isOpen,close}) => {
    const [getInfo, { data }] = useLazyQuery(get_info, {
        displayName: "GetAPIInfo",
    })
    const classes = useStyles();
    React.useEffect(() => {
        const handleClick = (e) => {
            if (!e.target.closest(`.${classes.root}`)) {
            close();
          }
        };
        if(isOpen) {
            getInfo();
          window.addEventListener("click", handleClick);
        }
        return () => window.removeEventListener("click", handleClick);
      }, [isOpen, close]);
    

    return (
        <Box component="section" className={classes.root}>
            <Container maxWidth="md">
                <Typography variant='h4' component='h1'>About</Typography>
                <Typography variant='subtitle1' component='p'>API Info: {data?.info?.about}</Typography>
                <Typography variant='subtitle1' component='p'>API Company: {data?.info?.company}</Typography>
                <Typography variant='subtitle1' component='p'>API Version: {data?.info?.version}</Typography>
                <Button variant='contained' color='secondary' onClick={close}>Close</Button>
                            </Container>
        </Box>
    )
}

export default GetAPIAbout
